





































import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class DirectAccessMenu extends Vue {
  @Prop() data!: any; // [{img,link,title}...]

  mounted() {}
}

















































































import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";

import "./EditDraw.scss";
import { DrawTools } from "./libs/DrawTools";
import { DrawPaths } from "./libs/DrawPaths";
import { EditPathMath } from "./libs/EditPathMath";
import { Calcs } from "./libs/Calcs";
import { Datas } from "./Datas";
import ToolpanelMap from "./libs/ToolpanelMap.vue";
import DrawType from "./DrawType";
import { CrudService } from "../../core/services/crudService";
import { Settings } from "../../core/services/configService";
import store from "../../core/store";

@Component({
  components: { ToolpanelMap },
})
export default class EditDraw extends Vue {
  @Prop({ default: "" }) public readonly ImageMap!: string;
  @Prop() templateId!: any;
  @Prop() bookingId!: string;
  @Prop() jsonDataPlaces!: any[];

  public href = "";
  public selPath = 0;
  public image: any = {};
  public width = "100%";
  public height = "100%";
  public svgH = 0;
  public svgW = 0;
  public viewBoxString = "0 0 0 0";
  public messageAlert = "";
  public messageAlertRed = false;
  public datas = new Datas();
  public paths: DrawType[] = [];
  public forms: any = [];
  public draws: any = [];
  public dataPlaces: any = [];
  public veditList: any = [];
  public vedit: any = [];
  public resizeOn = false;

  public nrange = 2;
  public toolParams: any = {
    nForms: 1,
    selTarget: [],
    fontSize: 20,
    nrange: null,
    cursor: "move",
  };

  public rectSel: any = {
    w: 100,
    h: 100,
    transform: "translate(500,100)",
    visible: false,
  };
  public csssection: any = [];

  private drawtools = new DrawTools();
  private drawPaths = new DrawPaths();
  private editPathMath: any = {};
  private editPathMouse: any = {};
  private calcs = new Calcs();

  private lastTarget: any = null;
  private initDrag = false;
  private internalImageMap: string = "";
  private idMap: any = null;
  private bookingPlaces: any[] = [];
  private internalJsonData: any[] = [];
  private bookingParticipants: any[] = [];
  private allowSeeParticipants: boolean = false;

  alertTitle: string = "";
  alertBody: string = "";
  alertError: string = "";
  showAlert: boolean = false;

  public setTool(op: any) {
    this.toolParams.cursor = op;
    this.drawtools.cursor = op;
  }

  private async created() {
    await this.loadData();
  }

  private async loadData() {
    const settings = store.state.settings;

    let f = new CrudService();
    let url = `${Settings.HostName}/api/publicbookings/booking-template-info/${this.templateId}/${this.bookingId}`;
    let res = await f.httpGet(url);
    if (res == null || res.model === null || res.model === undefined) {
      console.error("error getting visual booking type info");
      return;
    }
    let imgMap = "";
    this.bookingParticipants = res.model.participants;
    this.allowSeeParticipants = res.model.allowSeeParticipants;
    imgMap = `${settings.Uploads}/${res.model.imageMap}`;

    this.internalImageMap = "";
    this.internalJsonData = [];

    this.setJsonData(res.model.templateMap);
    this.bookingPlaces = res.model.places;
    this.buildPlaces(this.bookingPlaces);
    this.internalImageMap = imgMap;
  }

  private setJsonData(rowData: any) {
    this.internalJsonData = [];
    if (rowData.configJson !== "") {
      this.internalJsonData = JSON.parse(rowData.configJson);
    }
    this.idMap = rowData.id;
  }

  private buildPlaces(data: any[]) {
    var placeBuilts: any[] = [];
    data.forEach((element) => {
      var placeJson = [];
      if (element.configJson !== "") {
        placeJson = JSON.parse(element.configJson);
      }

      placeBuilts.push(placeJson);
    });

    return placeBuilts;
  }

  private initDraws() {
    if (this.internalImageMap !== "" && this.internalImageMap !== undefined) {
      if (this.datas.paths.length > 0) {
        this.datas.paths[0].data = [];
      }

      // load DATA from Database OR from Datas.ts Initial
      if (this.internalJsonData == null || this.internalJsonData.length === 0) {
        this.paths = this.datas.paths;
      } else {
        this.paths = this.internalJsonData;
      }
      if (this.jsonDataPlaces != null) {
        this.paths[0].data = this.jsonDataPlaces;
      }
      const paths: DrawType = this.paths[this.selPath];
      this.href = this.internalImageMap;

      this.nrange = 1;//Minimum zoom. Otherwise use paths.scale;
      this.toolParams.nrange = this.nrange;

      this.toolParams.fontSize = paths.fontSize;

      this.drawtools.href = this.href;
      this.drawtools.svgContent = this.$refs.svgCnt;
      this.drawtools.bkContent = this.$refs.imgBk;
      this.drawtools.image = this.image;
      this.drawtools.onResize = true;
      this.drawtools.onMove = true;
      this.drawtools.onLoadBk = this.onLoadBk;
      this.drawtools.multiply = paths.zoom;

      this.drawPaths.fontSize = this.toolParams.fontSize;
      this.drawPaths.editPathMath = this.editPathMath;
      this.drawtools.clickToMap = this.clickToMap;
      this.drawtools.moveSelect = this.moveSelect;

      this.editPathMath.multiply = paths.zoom;
      this.editPathMath.nrange = this.nrange;

      this.drawtools.init();
      this.draws = this.drawPaths.getGroupsPath(paths);
      this.forms = paths.paths;
    }
  }

  public findRowCol(draw: any, json: any) {
    const places: string[] = json.places;
    const colors: number[] = json.colors;
    const palettes: string[] = json.palettes;

    //const n = places.length;
    const rowcol = draw.row + "-" + draw.col;
    const fpos = places.indexOf(rowcol);
    const find = fpos >= 0;
    let color = "";
    if (find) {
      const ncolor = colors[fpos];
      color = palettes[ncolor];
    }
    const params: any = {
      find,
      color,
    };
    return params;
  }

  public initSections(sections: any[]) {
    if (sections === null || sections === undefined) {
      return;
    }
    const n = sections.length;
    for (let i = 0; i < n; i++) {
      const places = sections[i].places;
      if (places !== "") {
        const json = JSON.parse(places);
        if (json.palettes.length > 0) {
          let color1 = json.palettes[0];
          sections[i].color = color1;
        }
      }
    }
  }

  @Emit("placeSelected")
  public clickFnc(event: any, dat: any) {
    var booked = this.checkBookedPlace(dat.placeId);
    if (booked !== undefined) {
      return null;
    }

    var bookingPlace = this.bookingPlaces.find((t) => t.id == dat.placeId);

    return {
      bookingId: this.bookingId,
      placeId: bookingPlace.id,
      place: bookingPlace.place,
    };
  }

  private checkBookedPlace(placeId:any) {
    return this.bookingParticipants.find(
      (t) => t.bookingTemplatePlaceId == placeId
    );
  }

  public showTooltip(event: any, item: any) {
    let tooltip = document.getElementById("tooltip");
    if (tooltip === undefined) {
      return;
    }
    var tip = this.getHtmlTooltip(item);
    if (tip === null) {
      return;
    }
    tooltip.innerHTML = this.getHtmlTooltip(item);
    tooltip.style.display = "block";
    tooltip.style.left = event.pageX + 10 + 'px';
    tooltip.style.top = event.pageY + 10 + 'px';
  }

  public hideTooltip() {
    var tooltip = document.getElementById("tooltip");
    if (tooltip === undefined) {
      return;
    }
    tooltip.style.display = "none";
  }

  public getHtmlTooltip(item: any) {
    if (item === undefined || item === null) {
      return null;
    }

    if (this.bookingParticipants === undefined || this.bookingParticipants === null) {
      return null;
    }

    var placeId = item.placeId;

    var sSelected = this.bookingParticipants.find((t) => t.bookingTemplatePlaceId == placeId);
    if (!sSelected) {
      return this.$t("Not booked").toString();
    }

    if (!this.allowSeeParticipants) {
      return this.$t("Booked");
    }

    var tip = "";

    if (sSelected.logo !== null) {
      var logo = `${store.state.settings.Uploads}/${sSelected.logo}`;
      tip = "<img class='imageTooltip' src='" + logo + "'/>";
    }

    tip += "<div>" +
      this.$t("Booked by").toString() +
      ": " +
      sSelected.name + "</div>";
    tip += "<div>" +
      this.$t("Phone").toString() +
      ": " +
      (sSelected.phone == null ? "" : sSelected.phone) +
      "</div>";
    tip += "<div>" +
      this.$t("Email").toString() + ": " + sSelected.email + "</div>";

    return tip;
  }

  public onChangeZoomFnc() {
    this.onChangeZoom();
    this.setEditDrawSelect(this.lastTarget);
  }
  public selectDraw(dat: any, ind: number) {
    this.selectInd = ind;
    dat.isActive = true;

    if (dat.op !== "guides") {
      this.toolParams.labelSel = dat.label;
      this.toolParams.row = dat.row;
      this.toolParams.col = dat.col;

      if (this.lastTarget !== dat) {
        const n = this.toolParams.selTarget.length;
        if (n > 0) {
          this.toolParams.selTarget[n - 1].isActive = false;
        }

        this.toolParams.selTarget.push(dat);
        this.lastTarget = dat;
      }
    } else {
      this.setEditDrawSelect(dat);
    }
  }

  public setEditDrawSelect(dat: any) {
    if (dat !== null) {
      this.editPathMath = new EditPathMath();
      const vedit = this.editPathMath.setEditDraw(dat);

      this.veditList = [];
      this.veditList.push(vedit);
    }
  }

  public MouseDownFnc(event: any) {
    this.drawtools.downFnc(event);
  }

  // v-on:mousedown to Map: Move (drag) Image.
  public startDrag() {
    if (!this.editPathMouse.resizeOn) {
      this.initDrag = true;
      setTimeout(() => {
        if (this.initDrag) {
          const esc = this.getScale();
          this.drawtools.scale = esc;
          this.editPathMouse.scale = esc;
        }
      }, 5);
    }
  }

  public endDrag(event: any, dat: any) {
    this.initDrag = false;
    dat.isDragon = false;
  }

  public resizeInit(event: any, dat: any) {
    this.editPathMouse.resizeOn = true;
    this.editPathMouse.dragOn = false;
    this.drawtools.resizeOn = true;
    this.drawtools.dragOn = false;

    this.editPathMouse.startResize(
      event,
      dat,
      this.nrange,
      // endResize:
      (datNew: any, dx1: number, dy1: number) => {
        this.endResize(datNew, dx1, dy1);
      }
    );
  }

  public cssSection(section: any) {
    const ind = this.csssection.indexOf(section);
    if (ind === -1) {
      this.csssection.push(section);
    }
    return ind;
  }

  public getPathStyle(item: any) {
    let css = "";
    if (item.isActive) {
      css += " selected";
    }
    if (item.isDragon) {
      css += " ismovable";
    }
    if (Number(item.section) > 0) {
      css += " sect0" + this.cssSection(item.section);
    }

    if (this.bookingParticipants != null) {
      var sSelected = this.bookingParticipants.find(
        (t) => t.bookingTemplatePlaceId == item.placeId
      );
      if (!sSelected) {
        return css;
      }
      css += " bookedPlace";
    }

    return css;
  }

  private endResize(datNew: any, dx1: number, dy1: number) {
    let op: number = 1;

    if (datNew.type === "a" && datNew.css === "cent") {
      op = 2;
    }
    if (op === 1) {
      if (datNew.prev !== undefined) {
        datNew.prev.dat.def = this.editPathMath.resizeDraw(
          datNew.prev,
          dx1,
          dy1,
          op
        );
        datNew.next.dat.def = this.editPathMath.resizeDraw(
          datNew.next,
          dx1,
          dy1,
          op
        );
      }
    }

    datNew.dat.def = this.editPathMath.resizeDraw(datNew, dx1, dy1, op);

    // const w1 = 0;
    let h1 = 0;

    //const def = datNew.dat.def;
    const aa = this.drawPaths.getCentroid1(datNew.dat);

    h1 = aa[1] + this.toolParams.fontSize / 4;
    const textTrans = " translate(" + aa[0] + "," + h1 + ") ";
    datNew.dat.textTrans = textTrans;

    const vedit = this.editPathMath.setEditDraw(datNew.dat);

    this.veditList = [];
    this.veditList.push(vedit);

    this.editPathMouse.resizeOn = false;
    this.editPathMouse.dragOn = false;
    this.drawtools.resizeOn = false;
    this.drawtools.dragOn = false;
  }

  @Watch("internalImageMap")
  private onImageMapChange() {
    this.initDraws();
  }

  private showMessage(msg: any, alert?: any) {
    this.messageAlert = msg;
    this.messageAlertRed = alert;

    setTimeout(() => {
      this.messageAlert = "";
    }, 4000);
  }

  private moveSelect(params: any) {
    if (params.e1 === undefined) {
      this.rectSel.visible = false;
    } else {
      this.moveSelectRect(params);
    }
  }

  private moveSelectRect(params: any) {
    const e1 = params.e1;
    const e2 = params.e2;

    const esc = this.getScale() * this.nrange;

    const sx = Math.sign(params.dx);
    const sy = Math.sign(params.dy);

    let x1 = e2.offsetX / esc;
    let y1 = e2.offsetY / esc;

    if (sx === -1) {
      x1 = e1.offsetX / esc;
    }
    if (sy === -1) {
      y1 = e1.offsetY / esc;
    }

    this.rectSel.w = Math.abs(params.dx / esc);
    this.rectSel.h = Math.abs(params.dy / esc);

    this.rectSel.transform = `translate(${x1},${y1})`;

    this.rectSel.visible = true;

    const paramsTo: any = {
      w: this.rectSel.w,
      h: this.rectSel.h,
      x1,
      y1,
    };
    this.checkSelection(paramsTo, this.draws);
  }

  private checkSelection(params: any, placeList: any[]) {
    const poligon = [
      { x: params.x1, y: params.y1 },
      { x: params.x1 + params.w, y: params.y1 },
      { x: params.x1 + params.w, y: params.y1 + params.h },
      { x: params.x1, y: params.y1 + params.h },
      { x: params.x1, y: params.y1 },
    ];

    const n = placeList.length;

    for (let i = 0; i < n; i++) {
      const draw = placeList[i];

      if (draw.op === "data") {
        const x0 = draw.x0;
        const y0 = draw.y0;

        const p = { x: x0, y: y0 };
        const inside = this.calcs.InsidePolygon(poligon, p);

        draw.isActive = inside === 1;
      }
    }
  }

  private paredPath(dat: any) {
    if (dat.op === "data") {
      //   const ddx = params.ddx;
      // const ddy = params.ddy;

      const x1 = dat.x0;
      const y1 = dat.y0;

      const col = dat.col;
      const row = dat.row;
      const pos = this.paths[0].data.findIndex(
        (e: any) => e.row === row && e.col === col
      );
      this.paths[0].data[pos].trans = [x1, y1];
    }
  }

  private clickToMap() {
    this.veditList = [];
  }

  private onChangeZoom() {
    this.nrange = this.toolParams.nrange;
    this.editPathMath.nrange = this.toolParams.nrange;
  }

  private onLoadBk(img: any) {
    this.image = img;
    const esc = this.getScale();
    this.svgW = Math.ceil(img.w * esc);
    this.svgH = Math.ceil(img.h * esc);

    const w2 = Math.ceil(img.w);
    const h2 = Math.ceil(img.h);
    this.viewBoxString = `0 0 ${w2} ${h2}`;
  }

  private getScale() {
    let esc = 1;
    if (this.drawtools.svgContent !== undefined) {
      const img = this.image;
      const maxW = this.drawtools.svgContent.offsetWidth;
      esc = (maxW / img.w) * 0.8;
    }
    return esc;
  }

  cleanAlert() {
    this.alertTitle = "";
    this.alertBody = "";
    this.alertError = "";
  }
}

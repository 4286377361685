import { LocalStorage } from "../../../shared/core/localStorage";

export class LocaleHelper {

    // take culture for locale translations. All cultures that start with es are translated into es-ES
    // If user selects culture, return selected
    static toLocaleCulture(culture: string): string {
        const storedCulture = LocalStorage.get("lcl");
        culture = (storedCulture === null || storedCulture === undefined) ? culture : storedCulture;
        return culture.split("-")[0] === "es" ? "es-ES" : culture;
    }
}


export enum Locales {
    enUS = 'en-US',
    esES = 'es-ES'
}

export enum TimeRange {
    Minutes = 0,
    Hours = 1,
    Days = 2,
    Weeks = 3,
    Months = 4,
    Years = 5,
}

export enum CustomFieldType {
    Text = 0,
    Bool = 1,
    PickList = 2,
}


export enum TextBlockType { Posts = 0, Testimonials = 1, Teams = 2 }
export enum SortType { ByNew = 1, ByPriceAsc = 2, ByPriceDesc = 3 }
export enum SortTypeBlog { ByNew = 1, ByOld = 2 }
export enum DiscountType { Money = 0, Percent = 1 };
export enum ModifyMode { Increment = 0, Decrement = 1, Exact = 2 };
export enum PaymentMethodType { Redsys = 1, MoneyVoucher = 6, SessionsVoucher = 7, HoursVoucher = 8, PayPal = 9, NetPay = 10, Stripe = 14, AuthorizeNet = 15 };
export enum DatePickerMode {
    DateTime = 0,
    Date = 1,
    Time = 2
}
export enum ProductCategory {
    Service = 0,
    Article = 1,
    PeriodicSale = 2,
    VoucherCampaign = 3
}

export const IsMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);


var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"breadcrumbs-custom-inset"},[_c('div',{staticClass:"breadcrumbs-custom context-dark"},[_c('div',{staticClass:"container"}),_c('div',{staticClass:"box-position",style:(("background-image: url('" + (_vm.$store.state.settings.HeaderImage) + "')"))})])]),_c('section',{staticClass:"section section-sm section-last bg-default text-md-left"},[_c('div',{staticClass:"container"},[_c('h4',[_vm._v(_vm._s(_vm.$t("Bookings")))]),(_vm.errorMsg != null && _vm.errorMsg != '')?_c('div',{staticClass:"background-main-color"},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")]):_vm._e(),_c('div',{staticClass:"row row-15 align-items-start card-group-custom card-group-corporate",attrs:{"id":"accordion1","role":"tablist","aria-multiselectable":"false"}},[_c('div',{staticClass:"col-lg-6"},[_c('article',{staticClass:"card card-custom card-corporate"},[_c('div',{staticClass:"card-header",attrs:{"id":"accordion1Heading1","role":"tab"}},[_c('div',{staticClass:"card-title"},[_c('a',{staticClass:"fl-bigmug-line-alarm31 main-color",attrs:{"role":"button","data-toggle":"collapse","data-parent":"#accordion1","href":"#accordion1Collapse1","aria-controls":"accordion1Collapse1","aria-expanded":"true"}},[_vm._v(_vm._s(_vm.$t("Next Bookings"))+" "),_c('div',{staticClass:"card-arrow"})])])]),_c('div',{staticClass:"collapse show",attrs:{"id":"accordion1Collapse1","role":"tabpanel","aria-labelledby":"accordion1Heading1"}},[(
                  _vm.data === null ||
                  _vm.data === undefined ||
                  _vm.data.next.length === 0
                )?_c('article',{staticClass:"card card-custom card-corporate marginTop"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row mt-0 align-items-start card-group-custom card-group-corporate"},[_c('article',{staticClass:"w-100"},[_vm._v(" "+_vm._s(_vm.$t("No next bookings"))+" ")])])])]):_vm._e(),(
                  _vm.data !== null && _vm.data !== undefined && _vm.data.next.length > 0
                )?_c('div',{staticClass:"card-body"},_vm._l((_vm.data.next),function(b,index){return _c('article',{key:index,staticClass:"box-icon-modern"},[_c('h5',{staticClass:"box-icon-modern-title"},[_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(b.bookingType)+" "+_vm._s(b.place))])]),(b.resourceName !== null && b.resourceName !== '')?_c('h6',{staticClass:"box-icon-modern-title"},[_vm._v(" "+_vm._s(b.resourceName)+" ")]):_vm._e(),_c('h6',{staticClass:"box-icon-modern-title"},[_vm._v(" "+_vm._s(b.start)+" "+_vm._s(b.duration)+" ")]),_c('div',{staticClass:"box-icon-modern-decor"}),_c('p',{staticClass:"box-icon-modern-text"},[_vm._v(" "+_vm._s(b.description)+" ")]),(b.canCancel === true)?_c('TriggerAnchor',{staticClass:"button button-md button-default-outline button-wapasha smallBtnFont",attrs:{"id":("cancel" + index + "_" + (b.id)),"label":_vm.$t('Cancel')},on:{"click":function($event){return _vm.cancel(b.id, $event)}}}):_vm._e()],1)}),0):_vm._e()])])]),_c('div',{staticClass:"col-lg-6"},[_c('article',{staticClass:"card card-custom card-corporate"},[_c('div',{staticClass:"card-header",attrs:{"id":"accordion1Heading4","role":"tab"}},[_c('div',{staticClass:"card-title"},[_c('a',{staticClass:"collapsed main-color",attrs:{"role":"button","data-toggle":"collapse","data-parent":"#accordion1","href":"#accordion1Collapse4","aria-controls":"accordion1Collapse4"}},[_vm._v(_vm._s(_vm.$t("Past Bookings"))+" "),_c('div',{staticClass:"card-arrow"})])])]),_c('div',{staticClass:"collapse",attrs:{"id":"accordion1Collapse4","role":"tabpanel","aria-labelledby":"accordion1Heading4"}},[(
                  _vm.data === null ||
                  _vm.data === undefined ||
                  _vm.data.past.length === 0
                )?_c('article',{staticClass:"card card-custom card-corporate marginTop"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row mt-0 align-items-start card-group-custom card-group-corporate"},[_c('article',{staticClass:"w-100"},[_vm._v(" "+_vm._s(_vm.$t("No past bookings"))+" ")])])])]):_vm._e(),(
                  _vm.data !== null && _vm.data !== undefined && _vm.data.past.length > 0
                )?_c('div',{staticClass:"card-body"},_vm._l((_vm.data.past),function(b,index){return _c('article',{key:index,staticClass:"box-icon-modern"},[_c('h5',{staticClass:"box-icon-modern-title"},[_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(b.bookingType)+" "+_vm._s(b.place))])]),(b.resourceName !== null && b.resourceName !== '')?_c('h6',{staticClass:"box-icon-modern-title"},[_vm._v(" "+_vm._s(b.resourceName)+" "+_vm._s(b.place)+" ")]):_vm._e(),_c('h6',{staticClass:"box-icon-modern-title"},[_vm._v(" "+_vm._s(b.start)+" "+_vm._s(b.duration)+" ")]),_c('div',{staticClass:"box-icon-modern-decor"}),_c('p',{staticClass:"box-icon-modern-text"},[_vm._v(_vm._s(b.description))])])}),0):_vm._e()])])])])])]),_c('CancelReturnsInfo',{key:_vm.reload,attrs:{"infoReturns":_vm.infoReturns}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
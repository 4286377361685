

































































import {
  Component,
  Vue,
} from "../../../shared/node_modules/vue-property-decorator";
import Slider from "../../../shared/components/slider/slider.vue";
import TrendingProducts from "../../../shared/components/trendingProducts/trendingProducts.vue";
import Gallery from "../../../shared/components/gallery/gallery.vue";
import UsInNumbers from "../../../shared/components/usInNumbers/usInNumbers.vue";
import Testimonial from "../../../shared/components/testimonial/testimonial.vue";
import Team from "../../../shared/components/team/team.vue";
import LastPosts from "../../../shared/components/lastPosts/lastPosts.vue";
import DirectAccessMenu from "../../../shared/components/directAccessMenu/directAccessMenu.vue";
import PopularProducts from "../../../shared/components/popularProducts/popularProducts.vue";
import Sponsors from "../../../shared/components/sponsors/sponsors.vue";
import {
  Settings,
  TenantSettings,
} from "../../../shared/core/services/configService";
import { CrudService } from "../../../shared/core/services/crudService";
import store from "../../../shared/core/store";
import Banners from "../../../shared/components/banners/banners.vue";
import ActivitiesBanner from "../../../shared/crm/components/activities/activitiesBanner.vue";

@Component({
  components: {
    Slider,
    DirectAccessMenu,
    TrendingProducts,
    Gallery,
    UsInNumbers,
    Testimonial,
    Team,
    LastPosts,
    PopularProducts,
    Sponsors,
    Banners,
    ActivitiesBanner,
  },
})
export default class IndexPage extends Vue {
  settings: TenantSettings = {};
  directAccesMenuData: any[] = [];
  czTemplateSettings: any;
  trendingProductImg: string = "";
  gallery: any[] = [];
  galleryData: any[] = [];
  galleryPath: string = "";
  usInNumbers: any = null;
  slider: any = null;

  async mounted() {
    this.settings = store.state.settings;
    await this.configureDirectAccess();

    const url = `${Settings.HostName}/api/czconfigs/template`;
    const data = await new CrudService().httpGet(url);
    if (data == null || data.data == null || !data.ok) {
      console.error("Crash getting cz template settings");
      return;
    }
    this.configureTrendingProducts(data.data.mainTrandingProductImage);
    this.cofigureUsInNumbers(data);
    this.slider = data.data;
    this.loadGallery();
  }

  loadGallery() {
    const dbGallery = localStorage.getItem(Settings.Prefix + "all-gallery"); // setted in czmenu todo: if no menu wont load
    if (dbGallery !== null) {
      const gallery = JSON.parse(dbGallery);
      this.galleryData = gallery;
      return;
    }
  }

  cofigureUsInNumbers(data: any) {
    if (
      data.data.number1 === null &&
      data.data.number2 === null &&
      data.data.number3 === null &&
      data.data.number4 === null
    ) {
      return;
    }

    this.usInNumbers = {
      number1: data.data.number1,
      number2: data.data.number2,
      number3: data.data.number3,
      number4: data.data.number4,
      textNumbers1: data.data.textNumbers1,
      textNumbers2: data.data.textNumbers2,
      textNumbers3: data.data.textNumbers3,
      textNumbers4: data.data.textNumbers4,
    };
  }

  configureTrendingProducts(img: string) {
    if (img !== undefined) {
      this.trendingProductImg = this.settings.Uploads + "/" + img;
    }
  }

  async configureDirectAccess() {
    const url = `${Settings.HostName}/api/publicbookings/booking-types-list`;
    const data = await new CrudService().httpGet(url);
    if (data == null || data.model == null || !data.ok) {
      console.error("Crash getting index booking types");
      return;
    }

    let i = 0;
    const populars = data.model.filter((y) => y.showInMainPage === true);
    for (const bt of populars) {
      if (i === 5) {
        break;
      }

      this.directAccesMenuData.push({
        link:
          bt.bookingByPlace === true
            ? "/bookings-byplace/" + bt.id
            : "/bookings/" + bt.id,
        title: bt.description,
      });
      i++;
    }

    if (data.model.length > 5 && populars.length != 0) {
      this.directAccesMenuData.push({
        link: "/bookingtypes",
        title: this.$t("View All") + " ...",
      });
    }
  }
}

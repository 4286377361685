




























import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { DatePickerMode } from "../../core/constants";
import store from "../../core/store";
import { DateHelper } from "../../core/helpers/dateHelper";
import { Format } from "../../core/helpers/formatHelper";
/***
 * Emits  datePickerChanged
 */
@Component({
  components: { flatPickr },
})
export default class DatePicker extends Vue {
  @Prop({ default: "" }) label;
  @Prop({ default: DatePickerMode.Date }) mode!: DatePickerMode;
  @Prop({ required: false }) field;
  @Prop({ required: false }) required;
  @Prop({ default: "form-control defaultPicker" }) flatPickrClass: any;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: 8 }) defaultHour!: number;
  @Prop({ default: 0 }) defaultMinute!: number;
  @Prop({ required: false }) flatPickrId: any;
  @Prop() defaultValue!: any;
  @Prop() inline!: boolean;
  @Prop() enableUpTo!: Date;

  date: Date | null = null;
  selectedDates: any[] = [];

  mounted() {
    // this.date =
    //   this.defaultValue !== undefined
    //     ? this.defaultValue
    //     : store.getters.localNow;
  }

  getFormat() {
    var format = store.state.settings.DateFormat;
    var flatPickerFormat: any = {};
    flatPickerFormat.dateFormat = "M/d/Y h:i K";
    flatPickerFormat.enableTime =
      this.mode == DatePickerMode.Time || this.mode === DatePickerMode.DateTime;
    flatPickerFormat.noCalendar = this.mode == DatePickerMode.Time;
    flatPickerFormat.defaultHour = this.defaultHour;
    flatPickerFormat.defaultMinute = this.defaultMinute;
    flatPickerFormat.firstDayOfWeek = format.includes("tt") ? 0 : 1; //sunday  monday
    const firstDayOfWeek = format.includes("tt") ? 0 : 1; //sunday  monday
    flatPickerFormat.locale = {
      firstDayOfWeek,
    };
    console.log(format + " " + flatPickerFormat.firstDayOfWeek);

    flatPickerFormat.time_24hr = format.includes("tt") ? false : true;
    flatPickerFormat.dateFormat = this.toFlatPickrFormat();
    flatPickerFormat.inline = this.inline;
    if (!Format.IsNull(this.enableUpTo)) {
      const from = new Date(store.getters.localNow);
      from.setHours(0, 0, 0);

      flatPickerFormat.enable = [
        {
          from: from,
          to: this.enableUpTo,
        },
      ];
    }
    return flatPickerFormat;
  }

  toFlatPickrFormat() {
    var format = store.state.settings.DateFormat;
    format = format.replace(" tt", ""); // Remove AM-PM info as it is set in time_24h parameter
    const formatParts = format.toString().split(" ");
    //Date part - Remove duplicates, leave year as Y, month as M. Example dd/MM/yyyy -> d/m/Y
    const dateFormat = formatParts[0]
      .replace(/y/g, "Y")
      .replace(/M/g, "m")
      .replace(/(.)\1+/g, "$1");

    if (this.mode == DatePickerMode.Date) {
      return dateFormat;
    }

    //Hour part - Remove duplicates, leave minute as i
    let hourFormat = formatParts[1].replace(/m/g, "i").replace(/(.)\1+/g, "$1");
    if (formatParts.length > 2) {
      //If K parameter is defined
      hourFormat += " " + formatParts[2];
    }
    if (this.mode == DatePickerMode.Time) {
      return hourFormat;
    }

    return dateFormat + " " + hourFormat;
  }

  @Emit("datePickerChanged")
  onChange(selectedDates) {
    const refsDate: any = this.$refs.date;
    this.selectedDates = selectedDates;
    return {
      field: this.field,
      strDate: refsDate.$el.value,
      date: selectedDates[0],
      systemStrDate: DateHelper.toSystemString(selectedDates[0]),
    };
  }
}

import esES from "./es-ES.json";
import enUS from "./en-US.json";
import { Locales } from "../../../shared/core/constants";


export const LOCALES = [
    { value: Locales.enUS, caption: 'English USA' },
    { value: Locales.esES, caption: 'Español' }
];

export const messages = {
    [Locales.esES]: esES,
    [Locales.enUS]: enUS
};

export const defaultLocale = Locales.enUS;


































































































































































































































































































































import { CrudService } from "../core/services/crudService";
import { Settings } from "../core/services/configService";
import Vue from "../node_modules/vue";
import Component from "../node_modules/vue-class-component";
import { LocalStorage } from "../core/localStorage";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import { DateHelper } from "../core/helpers/dateHelper";
import { Format } from "../core/helpers/formatHelper";
import Popup from "../components/popup/popup.vue";
import BookingsFilters from "./bookingsFilters.vue";
import DatePicker from "../components/datePicker/datePicker.vue";
import { ShopService } from "../core/services/shopService";
import { DatePickerMode, IsMobile } from "../core/constants";
import { Global } from "../core/helpers/global";
import SubmitButton from "../components/submitButton/submitButton.vue";
import store from "../core/store";

@Component({
  components: {
    VueSlickCarousel,
    Popup,
    DatePicker,
    BookingsFilters,
    SubmitButton,
  },
})
export default class BookingsPage extends Vue {
  errorMsg: string = "";
  data: any[] = [];
  dbData: any[] = [];

  date: Date | string = "";
  bookingTypeSelected: any | null = null;
  resourceTypeId: number | null = null;

  showPopUpConfirm: boolean = false;
  bookingSelected: any = {};
  searchKey: string = "";

  closeFilter: number = 0;
  openFilter: number = 0;
  filterButton: boolean = false;

  dbBookingTypes: any[] = [];
  resourceTypes: any[] = [];
  bookingTypes: any[] = [];
  durations: any[] = [];
  anticipationText: string = "";
  anticipationMins: any = null;
  cancellationText: string = "";

  showOtherResources: boolean = false;
  otherResourceTypes: any[] | null = null;
  dataByResource: any | null;
  otherResourceOptionIndex = 0;

  blocks: any[] = [];

  carouselSettings: any = null;

  loadCarrouselSettings() {
    this.carouselSettings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 8,
      slidesToScroll: 8,
      centerMode:
        this.dbData.length <= 8 && this.dbData.length != 0 ? true : false,
      responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            //dots: true,
          },
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
  }

  async mounted() {
    let url = `${Settings.HostName}/api/publicbookings/filters`;
    if (!Format.IsNull(this.$route.params.bookingTypeId)) {
      url += `/${this.$route.params.bookingTypeId}`;
    }

    let res = await new CrudService().httpGet(url);
    if (res == null || res.ok === false) {
      console.error("Crash getting filters");
      this.errorMsg = this.$t("Crash").toString();
      return;
    }

    this.dbBookingTypes = res.model.bookingTypes;
    this.bookingTypes = res.model.bookingTypes;
    this.resourceTypes = res.model.resourceTypes;
    this.anticipationText = res.model.anticipationText;
    this.anticipationMins = res.model.anticipationMins;
    this.cancellationText = res.model.cancellationText;
    this.setUpBookingTypeFilter();

    await this.loadData();

    document.addEventListener("click", this.doNotHideFilter);

    if (this.$store.state.loggedIn === false) {
      this.$store.commit("openLogin");
    }

    const domprev: any = document.getElementsByClassName("slick-prev");
    if (domprev !== null && domprev !== undefined && domprev.length > 0) {
      domprev[0].innerHTML = "<";
    }
  }

  /// the controls hides on global click
  doNotHideFilter() {
    let domFilterClose = document.getElementById("dom-filter-close");
    domFilterClose?.classList.add("active");
  }

  closeFilters() {
    this.closeFilter++; // to trigger watch
  }

  openFilters() {
    this.openFilter++; // to trigger watch
  }

  setUpBookingTypeFilter() {
    if (this.bookingTypes.length === 1) {
      const bType = this.dbBookingTypes[0];
      this.bookingTypeSelected = bType;
      return;
    }

    this.filterButton = true;
    if (this.$route.params.bookingTypeId !== undefined) {
      const bookingTypeId = Number(this.$route.params.bookingTypeId);
      this.bookingTypeSelected = this.dbBookingTypes.find(
        (y) => y.id == bookingTypeId
      );
      return;
    }
    setTimeout(this.openFilters, 100);
  }

  async selectBookingType(bType: any) {
    if (
      bType.byPlace === true &&
      (Format.IsNull(bType.allowCreateBookByPlace) ||
        bType.allowCreateBookByPlace === false)
    ) {
      return this.$router.push("/bookings-byplace/" + bType.id);
    }
    if (this.bookingTypeSelected === bType.id) {
      this.bookingTypeSelected = null;
      return;
    }

    this.bookingTypeSelected = bType;

    if (this.bookingTypeSelected !== null) {
      await this.loadData();
    }
  }

  async resourceTypeSelected(id: number) {
    if (this.resourceTypeId === id) {
      this.resourceTypeId = null;
      return;
    }

    this.resourceTypeId = id;

    if (this.bookingTypeSelected !== null) {
      await this.loadData();
    }
  }

  searchBookingType() {
    if (this.searchKey === "") {
      this.bookingTypes = this.dbBookingTypes;
    }

    const res = this.bookingTypes.filter(
      (y) => y.name.toLowerCase().indexOf(this.searchKey.toLowerCase()) !== -1
    );

    this.bookingTypes = res;
  }

  async loadData() {
    this.errorMsg = "";

    if (
      this.date == null ||
      this.date === undefined ||
      this.dbBookingTypes.length === 0
    ) {
      return;
    }

    if (
      this.bookingTypeSelected === null ||
      this.bookingTypeSelected === undefined
    ) {
      return;
    }

    let cid = 0;
    let sid = LocalStorage.getWithTimeout("cc");
    if (sid !== undefined && sid !== null) {
      cid = JSON.parse(sid).id;
    }

    this.data = [];
    const url = `${Settings.HostName}/api/publicbookings/list`;
    Global.TriggerDomId = "loader";

    const res = await new CrudService().httpPost(url, {
      date: this.date == "" ? store.getters.localNow : this.date,
      bookingTypeId: this.bookingTypeSelected.id,
      customerId: cid === 0 ? null : cid,
      resourceTypeId: this.resourceTypeId,
    });

    if (res == null) {
      console.error("Crash getting bookings");
      return;
    }

    if (
      res.ok === false &&
      res.errorMsg !== undefined &&
      res.errorMsg !== null
    ) {
      this.errorMsg = res.errorMsg;
      return;
    }

    if (res.model.list.length === 0) {
      this.errorMsg = res.model.errorMsg;
      this.data.length = 0;
      return;
    }

    this.data = res.model.list;
    this.otherResourceTypes = res.model.multipleResources;
    this.dbData = res.model.list;

    this.setUpDurations();
    this.loadCarrouselSettings();
  }

  setUpDurations() {
    this.durations = [];
    for (const resource of this.data) {
      for (const timetable of resource.timeTable) {
        for (const duration of timetable.durations) {
          if (this.durations.find((y) => y === duration) === undefined) {
            this.durations.push(duration);
          }
        }
      }
    }
    if (this.durations.length === 1) {
      this.durations = [];
    }
  }

  async selectDuration(
    e: any,
    duration,
    resource,
    start,
    key,
    index,
    noOnlineBlocks: any = null
  ) {
    e.preventDefault();

    if (this.$store.state.loggedIn === false) {
      this.$store.commit("openLogin");
      this.toogle(key, index, null, 0);
      return;
    }

    this.blocks.push({
      duration: duration,
      resourceId: resource.id,
      resourceName: resource.name,
      startDate: start,
      sstart: DateHelper.toCultureString(start),
      noOnline: false,
    });

    if (noOnlineBlocks !== null) {
      for (const noOnlineBlock of noOnlineBlocks) {
        this.blocks.push({
          duration: noOnlineBlock.duration,
          resourceId: noOnlineBlock.resourceId,
          resourceName: noOnlineBlock.resourceName,
          startDate: noOnlineBlock.start,
          sstart: DateHelper.toCultureString(noOnlineBlock.start),
          noOnline: true,
        });
      }
    }

    this.errorMsg = "";
    this.closeFilters();

    const othersLength =
      this.showOtherResources !== undefined && this.otherResourceTypes !== null
        ? this.otherResourceTypes.length
        : 0;

    if (othersLength != 0 && this.otherResourceOptionIndex < othersLength) {
      // show popup with other related resources options
      await this.loadRelatedResourceOptions(this.blocks[0]);
      this.showOtherResources = true;
      return;
    }
    this.showOtherResources = false;
    this.showPopUpConfirm = true;
  }

  async loadRelatedResourceOptions(block: any) {
    if (this.otherResourceTypes === null) {
      return;
    }

    let cid = 0;
    let sid = LocalStorage.getWithTimeout("cc");
    if (sid !== undefined && sid !== null) {
      cid = JSON.parse(sid).id;
    }

    const id = this.otherResourceTypes[this.otherResourceOptionIndex];
    const url = `${Settings.HostName}/api/publicbookings/date-availability`;

    const res = await new CrudService().httpPost(url, {
      date: block.startDate,
      customerId: cid === 0 ? null : cid,
      bookingTypeResourceId: id,
    });

    if (res == null) {
      console.error("Crash getting date-availability");
      return;
    }

    if (res.ok === false || res.model === null || res.model.length === 0) {
      this.errorMsg = this.$t(
        "Sorry nothing found. Try with other filters"
      ).toString();
      return;
    }
    this.otherResourceOptionIndex += 1;
    this.dataByResource = res.model;
  }

  async addToCart(e: any) {
    this.errorMsg = "";
    e.preventDefault();
    let cid = 0;
    let sid = LocalStorage.getWithTimeout("cc");
    if (sid !== undefined && sid !== null) {
      cid = JSON.parse(sid).id;
    }

    if (cid === 0 || cid === null) {
      this.errorMsg = this.$t("Please login or register").toString();
      return;
    }

    if (this.blocks.length === 0) {
      return;
    }

    const bookingTypeId = this.bookingTypeSelected.id;
    const bookingTypeName = this.bookingTypeSelected.name;
    let booking: any = {};
    booking.customerId = cid;
    booking.bookingTypeId = bookingTypeId;
    booking.bookingTypeName = bookingTypeName;
    booking.isValidated = false;
    booking.sendCommunications = false;
    booking.origin = 0; // todo to enum
    booking.bookingByPlace = this.bookingTypeSelected.byPlace;

    if (booking.customerId !== null) {
      for (let i = 0; i < this.blocks.length; i++) {
        const block = this.blocks[i];
        block.customerId = booking.customerId;
      }
    }

    let url = `${Settings.HostName}/api/publicbookings/prebook`;
    const res = await new CrudService().httpPost(url, {
      booking: booking,
      customerIsParticipant: true,
      blocks: this.blocks,
      isMultiple: false,
    });

    if (res === undefined) {
      // login
      this.showPopUpConfirm = false;
      this.errorMsg = "";
      return;
    }
    if (res == null) {
      console.error("Crash pre booking ");
      this.errorMsg = this.$t("Crash").toString();
      return;
    }

    if (res.ok === false) {
      if (res.model !== undefined && res.model !== null) {
        this.errorMsg = Object.values(res.model).join(", ");
        return;
      }
      this.errorMsg = res.errorMsg;
      return;
    }

    if (res.model.serviceFree === true) {
      return this.$router.push("/customer-bookings");
    }

    this.closeFilters();

    let sale = res.model;
    sale.title = sale.description;
    sale.cancelUrl = `publicbookings/cancel/${sale.bookingId}/${cid}`;

    new ShopService().addSaleToCart(
      sale,
      document.getElementById("addToCartBtn")
    );
    this.$router.push("/checkout");
  }

  async filterByDate(date: any) {
    this.date = date.systemStrDate;
    await this.loadData();
  }

  filterByDuration(e: any, duration: number) {
    e.preventDefault();
    this.data = this.dbData;

    this.data = [];
    this.dbData.forEach((val) => this.data.push(Object.assign({}, val)));

    for (const resource of this.data) {
      for (const timetable of resource.timeTable) {
        if (timetable.durations.find((y) => y === duration) === undefined) {
          resource.timeTable = resource.timeTable.filter(
            (y) => y !== timetable
          );
        }
      }
    }
  }

  toogle(key: number, index: number, timetable: any, resourceId: number) {
    const collapse: any = document.getElementById(
      `id_accordion${key}${index}Collapse1`
    );
    const accordion: any = document.getElementById(
      `#accordion${key}${index}Collapse1`
    );
    console.log(
      `id_accordion${key}${index}Collapse1` +
        collapse.classList.contains("collapsed")
    );

    if (collapse.classList.contains("collapsed")) {
      collapse.setAttribute("aria-expanded", true);
      collapse.classList.remove("collapsed");
      accordion.classList.add("show");
      this.getPrice(key, index, timetable, resourceId);
    } else {
      collapse.setAttribute("aria-expanded", false);
      collapse.classList.add("collapsed");
      accordion.classList.remove("show");
    }
  }

  async getPrice(
    key: number,
    index: number,
    timetable: any,
    resourceId: number
  ) {
    for (const duration of timetable.durations) {
      let spanPrice: any = document.getElementById(
        `id_price${key}${index}_${duration}`
      );

      let price = timetable.priceByDuration[duration];
      if (price !== null && price !== undefined) {
        spanPrice.innerHTML = Format.formatCurrency(price);
        continue;
      }

      let cid = 0;
      let sid = LocalStorage.getWithTimeout("cc");
      if (sid !== undefined && sid !== null) {
        cid = JSON.parse(sid).id;
      }

      const url = `${Settings.HostName}/api/publicbookings/loadprice`;
      Global.TriggerDomId = "`id_price${key}${index}_${duration}`";
      const res = await new CrudService().httpPost(url, {
        startDate: timetable.start,
        bookingTypeId: this.bookingTypeSelected.id,
        duration: duration,
        customerId: cid === 0 ? null : cid,
        resourceId: resourceId,
      });

      var displayPrice = store.state.settings.PriceWithTaxes
        ? res.price.price
        : res.price.netPrice;
      timetable.priceByDuration[duration] = displayPrice;
      spanPrice.innerHTML = Format.formatCurrency(displayPrice);
    }
  }

  datePickerMode() {
    return DatePickerMode.Date;
  }

  getEnableUpTo() {
    if (Format.IsNull(this.anticipationMins)) {
      return null;
    }
    return DateHelper.addMinutes(store.getters.localNow, this.anticipationMins);
  }

  getResourceImage(image: string) {
    if (image !== undefined && image !== "" && image !== null) {
      return store.state.settings.Uploads + "/" + image;
    }
    return store.state.settings.Uploads + "/" + store.state.settings.Mainlogo;
  }

  goToBottomPage(e: any) {
    this.closeFilters();
    e.preventDefault();
    window.scrollTo(0, document.body.scrollHeight);
  }

  beautifyHour(hour: string) {
    if (hour.indexOf("AM") !== -1 || hour.indexOf("PM") !== -1) {
      const splited = hour.split(" ");
      return `${
        splited[0]
      } <span class='main-color'>${splited[1].toLocaleLowerCase()}</span>`;
    }
    return hour;
  }

  isMobile() {
    return IsMobile;
  }
}












































































import { Component, Vue, Prop } from "vue-property-decorator";
import { InitCount } from "../../js/initCount.js";

@Component
export default class UsInNumbers extends Vue {
  @Prop() data!: any;

  mounted() {
    if (this.data !== null && this.data !== undefined) {
      InitCount();
    }
  }
}

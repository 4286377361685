import { render, staticRenderFns } from "./myEmailPage.vue?vue&type=template&id=27e769fc&scoped=true&"
import script from "./myEmailPage.vue?vue&type=script&lang=ts&"
export * from "./myEmailPage.vue?vue&type=script&lang=ts&"
import style0 from "./myEmailPage.vue?vue&type=style&index=0&id=27e769fc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../wellness/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27e769fc",
  null
  
)

export default component.exports

























































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import Popup from "../popup/popup.vue";
import LoginForm from "./loginForm.vue";
import RegisterForm from "./registerForm.vue";
import store from "../../core/store";
import { LocalStorage } from "../../core/localStorage";

@Component({
  components: { Popup, LoginForm, RegisterForm },
})
export default class Login extends Vue {
  @Prop({ default: false }) open!: boolean;
  @Prop({ default: false }) registerOption!: boolean;
  @Prop() returnUrl!: string;
  openLogin: boolean = false;

  error: string = "";
  loginTitle: string = "";

  loginMode: boolean = true;
  registerMode: boolean = false;
  resetPasswordMode: boolean = false;
  resetSent: boolean = false;
  registerOk: boolean = false;

  mounted() {
    this.openLogin = this.open;
    this.error = "";
    this.loginTitle = this.$t("Login").toString();
  }

  closeLogin() {
    this.loginTitle = this.$t("Login").toString();
    this.error = "";

    this.loginMode = true;
    this.registerMode = false;
    this.resetPasswordMode = false;
    this.registerOk = false;

    this.openLogin = false;
    this.resetSent = false;
    LocalStorage.delete("loginModeOn");
    store.commit("closeLogin");
  }

  toogleModeOn() {
    if (this.loginMode) {
      this.registerModeOn();
      return;
    }
    this.loginModeOn();
  }

  loginModeOn() {
    const dom: any = document.getElementById("fashionCtrl");
    if (dom !== null) {
      dom.classList.remove("active");
    }

    this.loginTitle = this.$t("Login").toString();
    this.resetPasswordMode = false;
    this.loginMode = true;
    this.registerMode = false;
  }

  registerModeOn() {
    const dom: any = document.getElementById("fashionCtrl");
    if (dom !== null) {
      dom.classList.add("active");
    }
    this.loginTitle = this.$t("Register").toString();
    this.resetPasswordMode = false;
    this.loginMode = false;
    this.registerMode = true;
  }

  @Watch("$store.state.loginIsOpen")
  loginIsOpenWatch() {
    const loginModeOn = LocalStorage.get("loginModeOn");
    if (loginModeOn === null || loginModeOn === undefined) {
      return;
    }
    LocalStorage.delete("loginModeOn");
    this.loginMode = loginModeOn === "true";
    this.toogleModeOn();
  }
}

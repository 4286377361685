































import { CrudService } from "../core/services/crudService";
import { Settings } from "../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";
import store from "../core/store";

@Component({
  components: {},
})
export default class ActivatePage extends Vue {
  responseMsg: string = "";
  newPass: string = "";
  repeatPass: string = "";
  errorNew: string = "";
  errorRepeat: string = "";
  passToken: string = "";

  async created() {
    this.passToken = this.$route.params.token;
    await this.validate();
  }

  async validate() {
    this.responseMsg = "";
    this.errorRepeat = "";
    this.errorNew = "";

    let url = `${Settings.HostName}/api/publiccustomers/activate`;

    const body = {
      token: this.passToken,
    };

    let data = await new CrudService().httpPost(url, body);

    if (
      data == null ||
      data === undefined ||
      data.ok === undefined ||
      data.ok === false
    ) {
      this.responseMsg = this.$t(
        "Sorry your activation link is expired"
      ).toString();
      return;
    }

    this.responseMsg = this.$t(
      "Your registration has been completed!"
    ).toString();

    setTimeout(() => {
      store.commit("openLogin", { backUrl: "/" });
    }, 1000);
  }
}

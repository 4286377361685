















































import Vue from "vue";
import Component from "vue-class-component";
import { LocalStorage } from "../../core/localStorage";
import store from "../../core/store";
import { ShopService } from "../../core/services/shopService";

@Component({
  components: {},
})
export default class MenuUpper extends Vue {
  currentLanguage: any = "";

  async mounted() {
    this.currentLanguage = LocalStorage.get("lcl");
    this.currentLanguage =
      this.currentLanguage === null || this.currentLanguage === undefined
        ? "es-ES"
        : this.currentLanguage;
  }

  selectLanguage(language: any) {
    this.currentLanguage = language;
    LocalStorage.add("lcl", language);
    this.$router.go(0);
  }

  getClass(language: any, hasDivider: boolean) {
    let c: any = "nav-link nav-transparent nav-text-small h-100 pt-2";
    c += hasDivider ? " nav-divider" : "";
    c += hasDivider ? "" : " pr-3";
    c += this.currentLanguage !== language ? " nav-inactive" : "";
    return c;
  }

  getClassLogin() {
    let c: any = "nav-link nav-transparent nav-text-small bigger";
    c += store.state.loggedIn
      ? " fl-bigmug-line-login9 "
      : " fl-bigmug-line-login12 ";
    return c;
  }

  getClassRegister() {
    return "nav-link nav-transparent nav-text-small fl-bigmug-line-lock64 bigger";
  }

  logInOut(login: any) {
    if (store.state.loggedIn !== true) {
      LocalStorage.add("loginModeOn", login);
      store.commit("openLogin");
      return;
    }
    new ShopService().removeCart();
    LocalStorage.delete("ctk");
    LocalStorage.delete("cc");
    window.location.reload();
  }
}

import { render, staticRenderFns } from "./myBookingsPage.vue?vue&type=template&id=22f85878&scoped=true&"
import script from "./myBookingsPage.vue?vue&type=script&lang=ts&"
export * from "./myBookingsPage.vue?vue&type=script&lang=ts&"
import style0 from "./myBookingsPage.vue?vue&type=style&index=0&id=22f85878&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../wellness/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22f85878",
  null
  
)

export default component.exports
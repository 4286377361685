import { LocalStorage } from "./localStorage";
import Vue from "vue";
import Vuex, { Store } from "vuex";
import { SettingsService } from "./services/configService";

Vue.use(Vuex);

const settings: any = {};
const loginIsOpen: boolean = false;
const loginRedirect: string = "";
const registerRedirect: string = "";
const globalErrorIsOpen: boolean = false;
const globalErrorMsg: string = "";
const cart: any = LocalStorage.getWithTimeout("crt");
const loggedIn: any = LocalStorage.getWithTimeout("ctk") !== null;

const state = {
    settings,
    loginIsOpen,
    loginRedirect,
    registerRedirect,
    globalErrorIsOpen,
    globalErrorMsg,
    loggedIn,
    cart
};

const mutations = {
    openLogin(state: any, payload: any) {
        state.loginIsOpen = true;
        if (payload !== undefined) {
            state.loginRedirect = payload.backUrl;
            state.registerRedirect = payload.registerBackUrl;
        }
    },
    closeLogin(state: any) {
        state.logingRedirect = "";
        state.registerRedirect = "";
        state.loginIsOpen = false;
    },
    setCart(state: any, value: number) {
        state.cart = value;
    },
    setSettings(state: any, settings) {
        state.settings = settings;
    },
    openGlobalError(state: any, msg: string) {
        state.globalErrorIsOpen = true;
        state.globalErrorMsg = msg;
    },
    closeGlobalError(state: any) {
        state.globalErrorIsOpen = false;
        state.globalErrorMsg = "";
    },
};

const actions = {
    openLogin: ({ commit }) => commit('openLogin'),
    closeLogin: ({ commit }) => commit('closeLogin'),
    openGlobalError: ({ commit }) => commit('openGlobalError'),
    closeGlobalError: ({ commit }) => commit('closeGlobalError'),
    async loadSettings({ commit }) {
        const settings = await new SettingsService().loadConfig();
        commit("setSettings", settings);
    }
};

const getters = {
    localNow(state) {
        return new Date(new Date().toLocaleString('en', { timeZone: state.settings.TimeZone }));
    }
};

export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions
});



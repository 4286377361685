import store from "../store";

export class Format {

    static toLowerFirst(value: string) {
        return value.charAt(0).toLowerCase() + value.slice(1);
    }

    static formatCurrency(value: string) {
        if (value != "0" && (value == "" || value == null || value == undefined)) return "";
        const formated = this.formatNumber(value);
        return formated + store.state.settings.CurrencySymbol;
    }

    static formatNumber(value: string) {
        if (value != "0" && (value == "" || value == null || value == undefined)) return "";
        if (value.toString().indexOf('.') === -1) {
            return value;
        }
        let culture = store.state.settings.Culture;
        if (culture === "" || culture === null || culture === undefined) {
            culture = "en-US";
        }
        const formatter = new Intl.NumberFormat(culture, {
            minimumFractionDigits: 2
        });
        const s = formatter.format(Number(value));

        return s;
    }

    static removeNonPrintableChars(value: string) {
        if (Format.IsNull(value)) {
            return value;
        }
        return value.replace(/[^\x00-\x7F]/g, "");
    }

    static highLightText(words: any, query: string) {
        const iQuery = new RegExp(query, "ig");
        return words.toString().replace(iQuery, function (matchedTxt, a, b) {
            return ('<span class=\'post-creative-time\'>' + matchedTxt + '</span>');
        });
    }

    static IsNull(value: any) {
        return value === "" || value === null || value === undefined;
    }
}
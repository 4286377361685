import { LocalStorage } from "../../core/localStorage";
import { Settings } from "../../core/services/configService";
import { CrudService } from "../../core/services/crudService";

export class CustomerHelper {
    static getStoredCustomer(): number | undefined {
        try {
            return JSON.parse(LocalStorage.getWithTimeout("cc")).id;
        } catch {
            console.info("not customer logged found");
            return undefined;
        }
    }
    static async getCustomerData(cid: number, url: string | null = null) {
        url = url === null || url === '' ? `${Settings.HostName}/api/publiccustomers/${cid}` : url;
        const data = await new CrudService().httpGet(url);

        if (data == null || data === undefined || data.ok === false) {
            console.error("Crash getting customer " + cid);
            return;
        }
        return data;
    }

    static async getCreditCardData(cid: number) {
        const url = `${Settings.HostName}/api/publicpaymentmethods/token-list/${cid}`;
        const data = await new CrudService().httpGet(url);

        if (data == null || data === undefined || data.ok === false) {
            console.error(
                "Crash getting public payment methods " + cid
            );
            return null;
        }
        return data;
    }

    static async getSettings() {
        const url = `${Settings.HostName}/api/czconfigs/legal-settings`;
        const data = await new CrudService().httpGet(url);
        if (data == null || data.data == null || !data.ok) {
            console.error("Crash getting cz legal settings");
            return;
        }

        return data;
    }

    static async saveCustomer(customer: any, url: string) {
        const cid = CustomerHelper.getStoredCustomer();
        let parsedCustomFields: any[] | undefined = undefined;

        for (let i = 0; i < customer.customFields.length; i++) {
            const cf = customer.customFields[i];
            const customFieldValue: any = {
                identity: customer.id,
                customFieldId: cf.id,
                value: cf.value,
                customField: {
                    name: cf.name,
                    label: cf.label,
                    id: cf.id
                },
            };
            if (parsedCustomFields === undefined) {
                parsedCustomFields = [];
            }
            parsedCustomFields.push(customFieldValue);
        }

        if (parsedCustomFields !== undefined) {
            customer.customFieldValues = JSON.stringify(parsedCustomFields);
        }

        const data = await new CrudService().httpPut(url, customer);
        if (data == null || data === undefined) {
            console.error("Crash saving customer " + cid);
            return;
        }

        return data;
    }

    static async deleteCreditCard(cardId: number) {
        if (cardId == undefined || cardId == null) return;
        const cid = this.getStoredCustomer();
        if (cid === undefined) {
            return;
        }
        const url = `${Settings.HostName}/api/publicpaymentmethods/${cid}/${cardId}`;
        const res = await new CrudService().httpDelete(url, null);
        if (res !== null && res !== undefined && res.ok === true) {
            await CustomerHelper.getCreditCardData(cid);
        }
        return res;
    }

    static processErrors(data: any, errors: string[]) {
        if (typeof data == "string" || data == undefined) {
            console.log(data);
            return "";
        }

        if (
            data.errorMsg !== undefined &&
            data.errorMsg !== null &&
            data.errorMsg !== ""
        ) {
            return data.errorMsg;
        }

        Object.keys(data).forEach(function (key) {
            errors[key] = data[key];
        });
    }
}
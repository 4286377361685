













































































































































import { CrudService } from "../core/services/crudService";
import { Settings } from "../core/services/configService";
import { TextBlockType, SortTypeBlog } from "../core/constants";
import Vue from "vue";
import Component from "vue-class-component";
import { DateHelper } from "../core/helpers/dateHelper";
import store from "../core/store";
import InputText from "../components/form/inputText.vue";

@Component({
  components: { InputText },
})
export default class BlogPage extends Vue {
  posts: any[] = [];
  error: string = "";
  sort: any[] = [];
  search: string = "";
  sortType: SortTypeBlog = SortTypeBlog.ByNew;
  firstRecord: number = 0;
  lastRecord: number = 0;
  pageSize: number = 30;
  totalCount: number = 0;
  paginationClass: string = "page-item page-item-control";
  paginationClassDisabled: string = "page-item page-item-control disabled";
  numPages: number = 4;

  async mounted() {
    this.lastRecord = this.pageSize;
    this.sort = [
      { value: 1, label: this.$t("Sort By New") },
      { value: 2, label: this.$t("Sort By Old") },
    ];

    await this.loadData();
  }

  async loadData() {
    let filters: any = {};
    let postTypeFilter: any = {};
    let searchFilter: any = {};

    let sort: any = [];
    if (this.sortType !== null) {
      switch (Number(this.sortType)) {
        case SortTypeBlog.ByNew:
          sort = [{ colId: "Created", sort: "desc" }];
          break;
        case SortTypeBlog.ByOld:
          sort = [{ colId: "Created", sort: "asc" }];
          break;
      }
    }

    postTypeFilter["TextBlockType"] = {
      type: "equals",
      filter: TextBlockType.Posts,
      filterType: "number",
    };

    if (this.search !== null && this.search !== "") {
      searchFilter["Title"] = {
        condition1: {
          type: "contains",
          filter: this.search,
          filterType: "text",
        },
        condition2: {
          secondField: "Text",
          type: "contains",
          filter: this.search,
          filterType: "text",
        },
        condition3: {
          secondField: "SmalText",
          type: "contains",
          filter: this.search,
          filterType: "text",
        },
        operator: "OR",
      };

      filters = Object.assign(filters, searchFilter);
    }

    filters = Object.assign(filters, postTypeFilter);

    let gridStatus = JSON.stringify({ filters: filters, sort: sort });
    gridStatus = encodeURIComponent(gridStatus);

    const url = `${Settings.HostName}/api/cztextblocks/list-by-page?startRow=${this.firstRecord}&endRow=${this.lastRecord}&gridStatus=${gridStatus}`;
    const data = await new CrudService().httpGet(url);

    if (data == null || data === undefined || data.ok === false) {
      console.error("Crash getting posts");
      return;
    }

    this.posts = data.data;

    this.totalCount = data.totalCount;
    if (this.lastRecord >= this.totalCount) {
      this.lastRecord = this.totalCount;
    }
    window.scrollTo(0, 0);
  }

  getImage(name: string) {
    if (name !== undefined && name !== "" && name !== null) {
      return store.state.settings.Uploads + "/" + name;
    }
    return store.state.settings.Uploads + "/" + store.state.settings.Mainlogo;
  }

  getDateFormatted(dt: string) {
    return DateHelper.formatDate(new Date(dt));
  }

  getText(text: string) {
    if (text === undefined || text === null || text.length < 100) {
      return text;
    }
    return text.substring(0, 100);
  }

  getTextMonth(date: string) {
    var created = DateHelper.toSystemDate(date) as Date;
    const month = created.getMonth();
    if (month !== undefined) {
      return DateHelper.getTextMonth(this, month);
    }
  }
  getDay(date: string) {
    var created = DateHelper.toSystemDate(date) as Date;
    return created.getDate();
  }

  getpaginationClassPrev() {
    return this.firstRecord <= 0
      ? this.paginationClassDisabled
      : this.paginationClass;
  }

  getpaginationClassNext() {
    return this.lastRecord >= this.totalCount
      ? this.paginationClassDisabled
      : this.paginationClass;
  }

  getItemListClass(index: number) {
    return index == 1
      ? "page-item pointer li-margin active"
      : "page-item pointer li-margin";
  }

  nextPage() {
    this.firstRecord += this.pageSize;
    this.lastRecord =
      this.firstRecord + this.pageSize >= this.totalCount
        ? this.totalCount
        : this.firstRecord + this.pageSize;
    this.loadData();
  }

  navigateTo(index: number) {
    let page = this.firstRecord / this.pageSize + (index - 1);
    this.firstRecord = page * this.pageSize;
    this.lastRecord =
      this.firstRecord + this.pageSize >= this.totalCount
        ? this.totalCount
        : this.firstRecord + this.pageSize;
    this.loadData();
  }

  previousPage() {
    this.firstRecord -= this.pageSize;
    this.lastRecord = this.firstRecord + this.pageSize;
    this.loadData();
  }

  async selectSort() {
    this.firstRecord = 0;
    this.lastRecord = this.pageSize;
    await this.loadData();
  }

  async selectSearch() {
    if (this.search === "" || this.search.length > 3) {
      this.firstRecord = 0;
      this.lastRecord = this.pageSize;
      await this.loadData();
    }
  }
}

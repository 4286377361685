





























import { Component, Vue, Prop } from "vue-property-decorator";
import { Format } from "../../core/helpers/formatHelper";

@Component
export default class InputSelect extends Vue {
  @Prop() cssClass!: string;
  @Prop() id!: string;
  @Prop() options!: any; // {value,label}
  @Prop() required!: boolean;
  @Prop() error!: string;
  @Prop() value!: string;

  selectedValue: any = "";

  mounted() {
    this.selectedValue = this.value;
  }

  getSelected() {
    const dom: any = document.getElementById(this.id);
    if (Format.IsNull(dom)) {
      return;
    }
    this.selectedValue = dom.value;
    return this.selectedValue;
  }

  isNull(value: string) {
    return Format.IsNull(value);
  }

  showRequiredLabel() {
    return (
      this.required === true &&
      (this.selectedValue === -1 || this.selectedValue === "-1") &&
      Format.IsNull(this.error)
    );
  }
}

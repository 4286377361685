








































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";
import { Settings } from "../../core/services/configService";
import { CrudService } from "../../core/services/crudService";
import CheckBox from "../form/checkBox.vue";
import InputDate from "../form/inputDate.vue";
import InputPassword from "../form/inputPassword.vue";
import InputSelect from "../form/inputSelect.vue";
import InputText from "../form/inputText.vue";
import DynamicField from "./dynamicField.vue";
import SubmitButton from "../submitButton/submitButton.vue";
import { Format } from "../../core/helpers/formatHelper";
import { CustomerHelper } from "../../crm/helpers/customerHelper";
import LegalCheck from "../legalCheck/legalCheck.vue";
import PubliCheck from "../publiCheck/publiCheck.vue";

@Component({
  components: {
    CheckBox,
    InputDate,
    InputPassword,
    InputSelect,
    InputText,
    DynamicField,
    SubmitButton,
    LegalCheck,
    PubliCheck,
  },
})
export default class DynamicForm extends Vue {
  /**
   * Emits:
   * onsave
   */

  @Prop() parentId!: string;
  @Prop() parentName!: string;
  @Prop() legalChecks!: boolean;

  error: string = "";
  errors: string[] = [];
  data: any[] | null = null;
  oneCol: boolean = true;

  okRss: boolean = true;
  acceptConditions: boolean = false;

  @Emit("dataLoaded")
  async mounted() {
    let url = `${Settings.HostName}/api/dynamicforms/form-def/${this.parentName}/${this.parentId}`;
    let data = await new CrudService().httpGet(url);
    if (data == null || data === undefined || data.ok === false) {
      console.error("Crash getting form definition ");
      return;
    }

    this.data = data.model;
    if (
      this.data == null ||
      this.data === undefined ||
      this.data.length === 0
    ) {
      return false;
    }

    const hasCol1 = this.data.filter((y) => y.col1).length != 0;
    const hasCol2 = this.data.filter((y) => y.col2).length != 0;
    if (hasCol1 && hasCol2) {
      this.oneCol = false;
    }
    return true;
  }

  @Emit("onSave")
  async save(e: any) {
    e.preventDefault();
    this.errors = [];
    this.error = "";
    if (this.data == null) {
      return false;
    }

    if (this.legalChecks && !this.acceptConditions) {
      this.error = this.$t("Please accept our legal conditions").toString();
    }

    for (const field of this.data) {
      if (field.required && Format.IsNull(field.value)) {
        this.errors[field.name] = this.$t("The field is required").toString();
      }
    }

    if (Object.keys(this.errors).length > 0 || this.error !== "") {
      return false;
    }

    let data = {
      parentId: this.parentId,
      parentName: this.parentName,
      fields: this.data,
    };

    const res: any = await new CrudService().httpFetch(
      `${Settings.HostName}/api/dynamicforms/save`,
      "post",
      this.parseForm(data),
      undefined,
      "multipart"
    );

    if (res === null || res === undefined) {
      this.error = this.$t("Crash").toString();
      return false;
    }

    if (!res.ok) {
      this.error = res.errorMsg;
      return false;
    }

    return { success: true, model: res.model };
  }

  parseForm(data: any) {
    let form = document.getElementById("dynamicform") as HTMLFormElement;
    var formData: any = new FormData(form);

    formData.append("parentName", data.parentName);
    formData.append("parentId", data.parentId);

    if (this.legalChecks) {
      formData.append("okRss", this.okRss);
      formData.append("acceptConditions", this.acceptConditions);
    }

    const c = CustomerHelper.getStoredCustomer();
    if (c !== undefined) {
      formData.append("customerId", c);
    }

    for (var i = 0; i < data.fields.length; i++) {
      let field = data.fields[i];
      formData.append(`Fields[][${i}][id]`, field.id);
      formData.append(`Fields[][${i}][name]`, field.name);
      formData.append(
        `Fields[][${i}][value]`,
        Format.IsNull(field.value) ? "" : field.value
      );
      if (field.isFile) {
        let dom: any = document.getElementById(field.name);
        if (dom !== null) {
          formData.append(field.name, dom.files[0]);
        }
      }
    }

    return formData;
  }
}








































import { Component, Vue } from "vue-property-decorator";
import { Settings } from "../../core/services/configService";
import { CrudService } from "../../core/services/crudService";
import { TextBlockType } from "../../core/constants";
import store from "../../core/store";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

@Component({
  components: {
    VueSlickCarousel,
  },
})
export default class Testimonial extends Vue {
  data: any[] = [];
  carouselSettings: any = null;

  async mounted() {
    let filter: any = {};
    filter["TextBlockType"] = {
      type: "equals",
      filter: TextBlockType.Testimonials,
      filterType: "enum",
    };
    const gridStatus = JSON.stringify({ filters: filter });

    const url = `${
      Settings.HostName
    }/api/cztextblocks/list?startRow=0&endRow=14&lookup=true&gridstatus=${encodeURIComponent(
      gridStatus
    )}`;

    const data = await new CrudService().httpGet(url);
    if (data == null) {
      console.error("Crash getting testimonials");
      return;
    }
    this.data = data.rowData;

    this.carouselSettings = {
      arrows: false,
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 3000,
      autoplaySpeed: 3000,
      cssEase: "ease-in-out",
    };
  }

  getImagePath(img: string) {
    if (img === undefined || img === null || img === "") {
      return store.state.settings.Uploads + "/" + store.state.settings.Mainlogo;
    }
    return store.state.settings.Uploads + "/" + img;
  }
}

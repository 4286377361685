






































































































































































































































































































































































































































































import { Settings } from "../../../shared/core/services/configService";
import Vue from "../../../shared/node_modules/vue";
import Component from "../../../shared/node_modules/vue-class-component";
import InputText from "../../../shared/components/form/inputText.vue";
import InputSelect from "../../../shared/components/form/inputSelect.vue";
import InputDate from "../../../shared/components/form/inputDate.vue";
import Checkbox from "../../../shared/components/form/checkBox.vue";
import Popup from "../../../shared/components/popup/popup.vue";
import { CustomerHelper } from "../../../shared/crm/helpers/customerHelper";
import SubmitButton from "../../../shared/components/submitButton/submitButton.vue";
import { CustomFieldType } from "../../../shared/core/constants";

@Component({
  components: {
    InputText,
    InputDate,
    InputSelect,
    Checkbox,
    Popup,
    SubmitButton,
  },
})
export default class AccountPage extends Vue {
  customer: any = null;
  error: string = "";
  errorDeleteCard: string = "";
  ok: string = "";
  errors: any = {};
  creditCards: any[] | null = null;
  showPopUpLegal: boolean = false;
  showPopUpGeneral: boolean = false;
  showPopUpPrivacy: boolean = false;
  showPopUpCookies: boolean = false;

  privacyPolicyFull: string = "";
  cookiesPolicy: string = "";
  generalConditions: string = "";

  async mounted() {
    let cid = CustomerHelper.getStoredCustomer();
    if (cid === undefined) {
      return;
    }
    this.customer = await CustomerHelper.getCustomerData(cid);
    this.creditCards = await CustomerHelper.getCreditCardData(cid);
    var settings = await CustomerHelper.getSettings();
    this.privacyPolicyFull = settings.data.privacyPolicyFull;
    this.cookiesPolicy = settings.data.cookiesPolicy;
    this.generalConditions = settings.data.generalConditions;
  }

  cleanErrors() {
    this.errors.accountfirstname = "";
    this.errors.accountlastname = "";
    this.errors.accountemail1 = "";
    this.errors.accountphone1 = "";
    this.error = "";
    this.errorDeleteCard = "";
  }

  async save(e: any) {
    e.preventDefault();
    const msg = this.$t("Field required").toString();
    this.cleanErrors();

    if (this.customer.name === "") {
      this.errors.accountfirstname = msg;
      this.error = msg;
      return;
    }

    if (this.customer.surname === "") {
      this.errors["accountlastname"] = msg;
      this.error = msg;
      return;
    }

    if (this.customer.email === "") {
      this.errors["accountemail1"] = msg;
      this.error = msg;
      return;
    }

    if (this.customer.phone === "") {
      this.errors["accountphone1"] = msg;
      this.error = msg;
      return;
    }

    this.customer.birthDate = (
      document.getElementById("account-birthDate") as HTMLInputElement
    ).value;
    this.customer.gender = (
      document.getElementById("account-gender") as HTMLInputElement
    ).value;

    this.ok = "";
    let url = `${Settings.HostName}/api/publiccustomers/put-json`;
    let data: any = await CustomerHelper.saveCustomer(this.customer, url);

    if (
      data.ok === false &&
      data.errorMsg !== "" &&
      data.errorMsg !== undefined &&
      data.errorMsg != null
    ) {
      this.error = data.errorMsg;
      return;
    }

    this.ok = this.$t("Data modified!").toString();
  }

  async deleteCard(e: any, cardId: any) {
    if (cardId == undefined || cardId == null) return;
    e.preventDefault();
    this.cleanErrors();

    this.ok = "";
    this.errorDeleteCard = "";
    let res: any = CustomerHelper.deleteCreditCard(cardId);
    if (res === null || res === undefined) {
      this.errorDeleteCard = this.$t("Crash").toString();
      return;
    }

    if (!res.ok) {
      this.errorDeleteCard = res.errorMsg;
      return;
    }

    this.ok = this.$t("Data modified!").toString();
  }

  isText(item: any) {
    return item.fieldType === CustomFieldType.Text;
  }

  isCheckbox(item: any) {
    return item.fieldType === CustomFieldType.Bool;
  }

  isPickList(item: any) {
    return item.fieldType === CustomFieldType.PickList;
  }

  getCustomPickListOptions(data: any) {
    let res: any[] = [];
    for (const item of data.pickListValues.split(",")) {
      res.push({ label: item.trim(), value: item.trim() });
    }
    return res;
  }
}

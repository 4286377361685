





































import { Component, Vue } from "vue-property-decorator";
import { TextBlockType } from "../../core/constants";
import { Settings } from "../../core/services/configService";
import { CrudService } from "../../core/services/crudService";
import store from "../../core/store";

@Component
export default class Team extends Vue {
  data: any[] = [];

  async mounted() {
    let filter: any = {};
    filter["TextBlockType"] = {
      type: "equals",
      filter: TextBlockType.Teams,
      filterType: "enum",
    };
    const gridStatus = JSON.stringify({ filters: filter });

    const url = `${
      Settings.HostName
    }/api/cztextblocks/list?startRow=0&endRow=14&lookup=true&gridstatus=${encodeURIComponent(
      gridStatus
    )}`;

    const data = await new CrudService().httpGet(url);
    if (data == null) {
      console.error("Crash getting professionals");
      return;
    }
    this.data = data.rowData;
  }

  getImagePath(img: string) {
    if (img === undefined || img === null || img === "") {
      return store.state.settings.Uploads + "/" + store.state.settings.Mainlogo;
    }
    return store.state.settings.Uploads + "/" + img;
  }
}
















import {
  Component,
  Vue,
} from "../../../shared/node_modules/vue-property-decorator";
import CzMenu from "../menu/czmenu.vue";
import Footer from "../../../shared/components/footer/footer.vue";
import store from "../../../shared/core/store";

@Component({
  components: {
    CzMenu,
    Footer,
  },
})
export default class InSecure extends Vue {
  created() {}

  async mounted() {
    const shared = store.state.settings;
    if (shared.ButtonsColor !== null && shared.ButtonsColor !== undefined) {
      document.documentElement.style.setProperty(
        "--main-color",
        shared.ButtonsColor
      );
    }
    if (shared.FontFamily !== null && shared.FontFamily !== undefined) {
      document.documentElement.style.setProperty(
        "--main-family",
        shared.FontFamily
      );
    }

    if (shared.FooterColor !== null && shared.FooterColor !== undefined) {
      document.documentElement.style.setProperty(
        "--main-footercolor",
        shared.FooterColor
      );
    }

    const logo = shared.Uploads + "/" + shared.Mainlogo;
    var link: any = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.head.appendChild(link);
    }
    link.href = logo;
  }
}

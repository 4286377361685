














































import Vue from "vue";
import Component from "vue-class-component";
import PopularProducts from "../components/popularProducts/popularProducts.vue";

@Component({
  components: { PopularProducts },
})
export default class PaymentKoPage extends Vue {
  error: string = "";

  async mounted() {
    // setTimeout(function () {
    //   window.scrollTo(0, 0);
    // }, 500);
    // new ShopService().removeCart();
  }
}





















































import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class InputFile extends Vue {
  @Prop() field!: string;
  @Prop() value!: string;
  @Prop() src!: string;
  @Prop() error!: string;

  url: string = "";
  cleanedName: any = "";
  entityValue: string = "";

  created() {
    this.url = this.src;
    this.cleanedName = this.field;
    this.entityValue = this.value;
  }

  @Emit("input")
  setFile(e: any) {
    var fileInput: any = document.getElementById(this.field);
    var fileName = fileInput.value.replace("C:\\fakepath\\", "");
    this.cleanedName = fileName;

    const file = e.target.files[0];
    if (this.src == null) {
      return;
    }
    this.url = URL.createObjectURL(file);
    return this.url;
  }

  clearFile() {
    var file: any = document.getElementById(this.field);
    file.value = null;
    this.cleanedName = null;
    this.entityValue = "";
  }
}

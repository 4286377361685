



































import { Component, Vue } from "vue-property-decorator";
import { Settings } from "../../core/services/configService";
import { CrudService } from "../../core/services/crudService";
import store from "../../core/store";

@Component({
  components: {},
})
export default class PopularPosts extends Vue {
  popular: any[] = [];

  async mounted() {
    const url = `${Settings.HostName}/api/cztextblocks/list-with-users?count=2&popular=true`;

    const data = await new CrudService().httpGet(url);
    if (data == null) {
      console.error("Crash getting last posts");
      return;
    }
    this.popular = data;
  }

  getImage(name: string) {
    if (name !== undefined && name !== "" && name !== null) {
      return store.state.settings.Uploads + "/" + name;
    }
    return store.state.settings.Uploads + "/" + store.state.settings.Mainlogo;
  }
}























































































































































































































































































































































































































import { CrudService } from "../core/services/crudService";
import { Settings, TenantSettings } from "../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";
import { LocalStorage } from "../core/localStorage";
import { Format } from "../core/helpers/formatHelper";
import { ModifyMode } from "../core/constants";
import { ShopService } from "../core/services/shopService";
import InputText from "../components/form/inputText.vue";
import InputPassword from "../components/form/inputPassword.vue";
import CreditCardForm from "../components/form/creditCardForm.vue";
import { SaleItem } from "../core/interfaces/saleItem";
import Popup from "../components/popup/popup.vue";
import Checkbox from "../components/form/checkBox.vue";
import SubmitButton from "../components/submitButton/submitButton.vue";
import TriggerAnchor from "../components/triggerAnchor/triggerAnchor.vue";
import CheckoutPayment from "./checkoutPayment.vue";

@Component({
  components: {
    InputText,
    InputPassword,
    Popup,
    Checkbox,
    CreditCardForm,
    SubmitButton,
    TriggerAnchor,
    CheckoutPayment,
  },
})
export default class CheckoutPage extends Vue {
  settings: TenantSettings = {};
  error: string = "";
  data: any = null;
  transport: any = null;
  errors: any = {};
  paymentMethodId: number | null = null;
  reloadErrors: number = 0;

  async mounted() {
    this.settings = this.$store.state.settings;
    if (
      this.$store.state.cart === null ||
      this.$store.state.cart === undefined
    ) {
      return this.$router.push("/");
    }

    if (this.$store.state.cart.count === 0) {
      return this.$router.push("/");
    }

    let i = 1;
    for (const saleItem of this.$store.state.cart.sItems) {
      saleItem.saleId = -1 * i; // saleId tmp to identify
      i++;
    }

    let cid = null;
    let sid = LocalStorage.getWithTimeout("cc");
    if (sid !== undefined && sid !== null) {
      cid = JSON.parse(sid).id;
    }

    let url =
      cid == null
        ? `${Settings.HostName}/api/shop/checkout-info`
        : `${Settings.HostName}/api/shop/checkout-info-by-customer`;

    let data = await new CrudService().httpPost(url, {
      customerId: cid,
      saleItems: this.$store.state.cart.sItems,
      saleIds: this.$store.state.cart.sales.map((y) => y.id),
    });

    if (data == null || data === undefined) {
      console.error("Crash getting checkout info " + cid);
      if (data.ok === false) {
        console.error(data.errorMsg);
      }
      return;
    }

    this.data = data.data;
    if (data.data === null) {
      console.error(data.errorMsg);
      return;
    }

    if (
      this.data.transports !== null &&
      this.data.transports !== undefined &&
      this.data.transports.length > 0
    ) {
      this.transportRowClick(this.data.transports[0]);
    }
  }

  removeSaleFromCart(e: any, saleId: number) {
    e.preventDefault();
    new ShopService().removeSaleFromCart(saleId);
  }

  removeSaleItemFromCart(e: any, saleItem: any) {
    e.preventDefault();
    new ShopService().removeSaleItemFromCart(saleItem);
  }

  incrementUnits(sItem: SaleItem) {
    new ShopService().modifyUnits(ModifyMode.Increment, sItem, 1);
  }

  decrementUnits(sItem: SaleItem) {
    new ShopService().modifyUnits(ModifyMode.Decrement, sItem, 1);
  }

  modifyUnits(sItem: SaleItem) {
    const units = Number(sItem.units);
    if (isNaN(units)) {
      return;
    }
    new ShopService().modifyUnits(ModifyMode.Exact, sItem, units);
  }

  showDeliveryInfo() {
    return (
      !this.$store.state.loggedIn ||
      (this.data !== null &&
        this.data.transports !== null &&
        this.$store.state.cart.sItems !== undefined &&
        this.$store.state.cart.sItems !== null &&
        this.$store.state.cart.sItems.length != 0)
    );
  }

  checkTransportRequired() {
    return this.$store.state.cart.sItems.some(
      (y) => y.units > 0 && y.product.transportRequired === true
    );
  }

  getImagePath(img: string) {
    if (img === null || img === undefined || img === "") {
      return this.settings.Uploads + "/" + this.settings.Mainlogo;
    }
    return this.settings.Uploads + "/" + img;
  }

  formatPrice(price: string) {
    return Format.formatCurrency(price);
  }

  transportRowClick(transport: any) {
    const dom = document.getElementById("trans_" + transport.id);
    if (dom !== null && dom !== undefined) dom.click();
    this.transport = transport;
  }

  async payVoucher(sale: any, saleItem: any, saleId: number) {
    this.error = "";

    let cid = 0;
    let sid = LocalStorage.getWithTimeout("cc");
    if (sid !== undefined && sid !== null) {
      cid = JSON.parse(sid).id;
    }

    const voucherSelectedDom: any = document.getElementById(
      `__voucher_for_${saleId}`
    );
    if (voucherSelectedDom === null) {
      return;
    }
    const vouchers = this.data.vouchers.find(
      (y) => y.saleId === saleId
    ).vouchers;

    let paymentMethodId = -1;
    let voucherId = -1;

    if (voucherSelectedDom.value === "") {
      // is only one option
      paymentMethodId = vouchers[0].id;
      voucherId = vouchers[0].voucher.id;
    } else {
      voucherId = Number(voucherSelectedDom.value);
      for (const v of vouchers) {
        if (v.voucher.id === voucherId) {
          paymentMethodId = v.id;
        }
      }
    }

    let url = `${Settings.HostName}/api/shop/checkout-customer`;

    const data = await new CrudService().httpPost(url, {
      saleIds: sale !== null && sale !== undefined ? [sale.id] : null,
      saleItems:
        saleItem !== null && saleItem !== undefined ? [saleItem] : null,
      paymentMethodId: paymentMethodId,
      voucherId: voucherId,
      customer: {
        id: cid,
      },
    });

    const errorMsg = this.$t(
      "Ups! Tecnical problems. Please try again in a few minutes"
    ).toString();

    if (data == null || data === undefined) {
      console.error("Crash checkout " + cid);
      this.error = errorMsg;
      return;
    }

    if (data.ok === false || data.errors !== undefined) {
      this.error = data.model.errorMsg !== "" ? data.model.errorMsg : errorMsg;
      return;
    }

    const shopService = new ShopService();
    if (saleItem !== null && saleItem !== undefined) {
      shopService.removeSaleItemFromCart(saleItem);
    }

    if (saleId > 0) {
      shopService.removeSaleFromCart(saleId, false);
    }

    if (!Format.IsNull(data.model) && !Format.IsNull(data.model.saleNumber)) {
      // partial payment with money voucher, reload sale bc it is not fully paid
      shopService.addSaleToCart(data.model, null);
      this.$router.go(0);
      return;
    }

    if (this.$store.state.cart.count === 0) {
      return this.$router.push("/cz/ok");
    }
    location.href = "/checkout";
  }
}

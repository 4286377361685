import { LocalStorage } from "../localStorage";
import store from "../store";
import { Loader } from "../../core/helpers/loaderHelper";
import { Format } from "../../core/helpers/formatHelper";
import { Global } from "../../core/helpers/global";

export class CrudService {

    async httpGet(url: string, body?: any, callback?: Function, contentType: string = "json") {
        return this.httpFetch(url, "GET", body, callback, contentType);
    }

    async httpPost(url: string, body?: any, callback?: Function, contentType: string = "json") {
        return this.httpFetch(url, "POST", body, callback, contentType);
    }

    async httpPut(url: string, body?: any, callback?: Function, contentType: string = "json") {
        return this.httpFetch(url, "PUT", body, callback, contentType);
    }

    async httpDelete(url: string, body?: any, callback?: Function, contentType: string = "json") {
        return this.httpFetch(url, "DELETE", body, callback, contentType);
    }

    async httpFetch(url: string, method: string, body?: any, callback?: Function, contentType: string = "json") {
        this.showLoader();
        const requestOptions = this.buildRequest(method, contentType, body);
        const response = await fetch(url, requestOptions);

        if (!response.ok) {
            const res = this.handleBadRequest(response, callback);
            this.hideLoader();
            return res;
        }

        const res = await this.handleResponse(response, callback);
        this.hideLoader();
        return res;
    }

    private showLoader() {
        if (Format.IsNull(Global.TriggerDomId)) {
            return;
        }

        Loader.Loading(Global.TriggerDomId);
    }

    private hideLoader() {
        if (Format.IsNull(Global.TriggerDomId)) {
            return;
        }
        Loader.Unloading(Global.TriggerDomId);
        Global.TriggerDomId = "";
    }


    private buildRequest(method: string, contentType: string, body?: any) {
        const token = LocalStorage.getWithTimeout("ctk");
        const version = localStorage.getItem(window.location.host + "_czv");
        const requestOptions: any = {
            method: method,
            headers: new Headers({
                'Accept': 'application/json',
                // 'Content-Type': this.getContentType(contentType),
                'Authorization': 'Bearer ' + token,
                'CzVersion': version !== null ? version : ''
            }),
            useCredentails: true,
            body: this.buildBody(contentType, body)
        };
        if (contentType != "multipart") {
            const headers: Headers = requestOptions.headers;
            headers.append('Content-Type', this.getContentType(contentType));
        }
        return requestOptions;
    }

    private handleResponse(response: Response, callback?: Function) {
        let data: any;

        this.checkVersion(response);

        const responseCType = response.headers.get("content-type");
        if (responseCType && (
            responseCType.includes("application/json") ||
            responseCType.includes("application/problem+json")
        )
        ) {
            data = response.json().then(json => {
                if (json.errors) {
                    if (callback) return callback(response.ok, json.errors);
                } else {
                    if (callback) return callback(response.ok, json);
                }
                data = json;
                return data;
            }).catch(e => {
                console.log(e);
                if (callback) return callback(false);
            });
        } else {
            console.log("Crash", response);
            if (callback) return callback(false, data);
            return data;
        }
        return data;
    }

    private handleBadRequest(response: Response, callback?: Function) {
        if (response.status === 401 || response.status === 403) {
            if (LocalStorage.getWithTimeout("ctk") != "") {
                LocalStorage.delete("ctk");
                if (callback) return callback(false);
                //location.href = "/";
                store.commit("openLogin");
            }
            return;
        }

        return this.handleResponse(response, callback);
    }

    private buildBody(ct: string, body?: any) {
        if (!body) {
            return null;
        }
        if (body && ct == "json") {
            return JSON.stringify(body);
        }
        return body;
    }

    private getContentType(ct: string) {
        if (ct == "json") return "application/json-patch+json";
        if (ct == "form") return "application/x-www-form-urlencoded";
        if (ct == "multipart") return "multipart/form-data";
        return "json";
    }

    checkVersion(response: Response) {
        const versionExpired = response.headers.get("Expired");
        if (versionExpired === null || versionExpired === undefined) {
            return;
        }
        localStorage.setItem(window.location.host + "_czv", versionExpired);
        localStorage.setItem(window.location.host + "_cz_new_version_reload", versionExpired);
        console.log("New version " + versionExpired);
        window.location.reload();
    }
}



































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Settings, TenantSettings } from "../../core/services/configService";
import InputText from "../../components/form/inputText.vue";
import VueGallery from "vue-gallery";
import store from "../../core/store";
import { Format } from "../../core/helpers/formatHelper";
import { CrudService } from "../../core/services/crudService";

@Component({
  components: {
    VueGallery,
    InputText,
  },
})
export default class Footer extends Vue {
  settings: TenantSettings = {};
  logo: string = "";
  images: string[] = [];
  index: any = null;

  hideRequestInfoFrom: boolean = false;
  email: string = "";
  question: string = "";
  requestInfoMessage = "";

  mounted() {
    this.requestInfoMessage = this.$t(
      "We will contact you as soon as possible"
    ).toString();

    this.settings = store.state.settings;
    if (this.settings === undefined) {
      return;
    }
    this.logo = this.settings.Uploads + "/" + this.settings.Mainlogo;

    if (this.settings.Gallery !== null && this.settings.Gallery !== undefined) {
      for (const image of this.settings.Gallery) {
        this.images.push(this.getImagePath(image.img));
      }
    }
  }

  getImagePath(imgName: string) {
    return this.settings.Uploads + "/CzConfig/CzGallery/" + imgName;
  }

  async requestMoreInfo(e: any) {
    e.preventDefault();
    if (Format.IsNull(this.email) || Format.IsNull(this.question)) {
      return;
    }
    const url = `${Settings.HostName}/api/publiccomms/info-request`;
    const questionRequest = {
      email: this.email,
      question: this.question,
    };
    const data = await new CrudService().httpPost(url, questionRequest);
    if (data == null || !data.ok) {
      console.error("Crash sending more info request email");
      return;
    }
    this.hideRequestInfoFrom = true;
    this.requestInfoMessage =
      "<b>" + this.$t("Thanks! we will contact you ASAP!" + "</b>").toString();
  }
}
















































































import { CrudService } from "../core/services/crudService";
import { Settings, TenantSettings } from "../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";
import { DateHelper } from "../core/helpers/dateHelper";
import { LocalStorage } from "../core/localStorage";
import store from "../core/store";

@Component({
  components: {},
})
export default class MyEmailPage extends Vue {
  email: any = {};
  settings: TenantSettings = {};

  async mounted() {
    this.settings = store.state.settings;
    let cid = 0;
    try {
      cid = JSON.parse(LocalStorage.getWithTimeout("cc")).id;
    } catch {
      console.error("not customer logged found");
    }
    await this.loadData(cid);
  }

  async loadData(customerId: number) {
    let url = `${Settings.HostName}/api/publiccomms/customer-comms/emails/${this.$route.params.id}/${customerId}`;
    let data = await new CrudService().httpGet(url);

    if (data == null) {
      console.error("Crash getting post");
      return;
    }
    this.email = data;
  }

  getDateFormatted(dt: string) {
    return DateHelper.formatDate(new Date(dt));
  }
}

































































import { Component, Vue, Prop } from "vue-property-decorator";
import { Settings } from "../../core/services/configService";
import { CrudService } from "../../core/services/crudService";
import { DateHelper } from "../../core/helpers/dateHelper";
import store from "../../core/store";

@Component({
  components: {},
})
export default class LastPosts extends Vue {
  @Prop() title!: string;
  posts: any[] = [];

  async mounted() {
    const url = `${Settings.HostName}/api/cztextblocks/list-with-users?count=3`;

    const data = await new CrudService().httpGet(url);
    if (data == null) {
      console.error("Crash getting last posts");
      return;
    }
    this.posts = data;
  }

  getImage(name: string) {
    if (name !== undefined && name !== "" && name !== null) {
      return store.state.settings.Uploads + "/" + name;
    }
    return (
      store.state.settings.Uploads.Uploads +
      "/" +
      store.state.settings.Uploads.Mainlogo
    );
  }

  getDateFormatted(dt: string) {
    return DateHelper.formatDate(new Date(dt));
  }
}


export class LocalStorage {

    public static addWithTimout(guid: string, data: any, expires: Date) {
        const object = { value: data, expires: expires };
        localStorage.setItem(window.location.host + "_" + guid, JSON.stringify(object));
        this.clean();
    }

    public static getWithTimeout(guid: string) {
        this.clean();
        const item = localStorage.getItem(window.location.host + "_" + guid);
        if (item !== null) {
            return JSON.parse(item).value;
        }
        return null;
    }


    public static delete(guid: string) {
        localStorage.removeItem(window.location.host + "_" + guid);
        this.clean();
    }

    public static get(guid: string) {
        this.clean();
        return localStorage.getItem(window.location.host + "_" + guid);
    }

    public static add(guid: string, data: any) {
        localStorage.setItem(window.location.host + "_" + guid, data);
    }

    static clean() {
        const keys = Object.keys(localStorage);
        let i = 0;
        let key = "";
        const countKeys = keys.length;

        const now = new Date();

        for (i = 0; i < countKeys; i++) {
            key = keys[i];
            if (key.startsWith(window.location.host + "_")) {
                const p = localStorage.getItem(key);
                if (p === null) {
                    continue;
                }
                let o: any = null;
                try {
                    o = JSON.parse(p.toString());
                    if (o == null) {
                        continue;
                    }
                } catch {
                    continue;
                }
                if (o.expires && o.expires !== undefined) {
                    const expiry = new Date(o.expires);
                    const diff = expiry.getTime() - now.getTime();
                    //const hours = Math.abs(diff) / 36e5;
                    if (diff < 0) {
                        localStorage.removeItem(key);
                    }
                }
            }
        }
    }

}
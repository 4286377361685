


















import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Format } from "../../core/helpers/formatHelper";

@Component({
  components: {},
})
export default class InputText extends Vue {
  @Prop() error!: string;
  @Prop() id!: string;
  @Prop() label!: string;
  @Prop() value!: string;
  @Prop({ default: "form-input form-control-has-validation" })
  inputClass!: string;
  @Prop() required!: boolean;
  @Prop() css!: string;
  @Prop({ default: false }) isEmail!: boolean;

  isNull(value: string) {
    return Format.IsNull(value);
  }

  @Emit("input")
  onChange(val: any) {
    return Format.removeNonPrintableChars(val);
  }
}
































































































































































































import { CrudService } from "../core/services/crudService";
import { Settings } from "../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";
import { LocalStorage } from "../core/localStorage";
import CancelReturnsInfo from "../components/cancelReturnsInfoCz/cancelReturnsInfoCz.vue";
import TriggerAnchor from "../components/triggerAnchor/triggerAnchor.vue";

@Component({
  components: {
    CancelReturnsInfo,
    TriggerAnchor,
  },
})
export default class MyBookingsPage extends Vue {
  data: any = null;
  errorMsg: string = "";
  infoReturns: any = null;
  reload: number = 1;

  async mounted() {
    let cid = 0;
    try {
      cid = JSON.parse(LocalStorage.getWithTimeout("cc")).id;
    } catch {
      console.error("not customer logged found");
    }

    await this.loadData(cid);
  }

  async loadData(customerId: number) {
    let url = `${Settings.HostName}/api/publicbookings/customer-bookings/${customerId}`;
    let data = await new CrudService().httpGet(url);
    if (data == null || data.ok === false) {
      console.error("Crash getting my bookings");
      return;
    }

    this.data = data.model;
  }

  async cancel(id: number, e: any) {
    this.errorMsg = "";
    e.preventDefault();
    let cid = 0;
    try {
      cid = JSON.parse(LocalStorage.getWithTimeout("cc")).id;
    } catch {
      console.error("not customer logged found");
    }

    const url = `${Settings.HostName}/api/publicbookings/cancel/${id}/${cid}`;
    let data = await new CrudService().httpPost(url);
    if (data == null) {
      console.error("Crash cancelling my bookings");
      return;
    }

    if (data.errorMsg !== null && data.errorMsg !== undefined) {
      this.errorMsg = data.errorMsg;
      return;
    }

    this.infoReturns = data.infoReturns;
    this.reload += 1;

    return await this.loadData(cid);
  }
}

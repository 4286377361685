import { render, staticRenderFns } from "./globalError.vue?vue&type=template&id=7cef2987&scoped=true&"
import script from "./globalError.vue?vue&type=script&lang=ts&"
export * from "./globalError.vue?vue&type=script&lang=ts&"
import style0 from "./globalError.vue?vue&type=style&index=0&id=7cef2987&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../wellness/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cef2987",
  null
  
)

export default component.exports
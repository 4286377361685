























import Vue from "vue";
import Component from "vue-class-component";
import Popup from "../popup/popup.vue";

@Component({
  components: { Popup },
})
export default class GlobalError extends Vue {}







































































































































































































































































import { CrudService } from "../core/services/crudService";
import { Settings } from "../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";
import { LocalStorage } from "../core/localStorage";
import { Format } from "../core/helpers/formatHelper";

@Component({
  components: {},
})
export default class MyVouchersPage extends Vue {
  data: any = null;
  errorMsg: string = "";

  async mounted() {
    let cid = 0;
    try {
      cid = JSON.parse(LocalStorage.getWithTimeout("cc")).id;
    } catch {
      console.error("not customer logged found");
    }

    await this.loadData(cid);
  }

  async loadData(customerId: number) {
    let url = `${Settings.HostName}/api/publicvouchers/customer-vouchers/${customerId}`;
    let data = await new CrudService().httpGet(url);
    if (data == null || data.ok === false) {
      console.error("Crash getting my vouchers");
      return;
    }

    this.data = data.model;
  }

  getMovementLineClass(movement: any) {
    if (Format.IsNull(movement) || movement.amount.startsWith("-")) {
      return "tblCol";
    }
    return "tblCol recharge";
  }
}

import Vue from '../../shared/node_modules/vue';
import VueI18n from './../../shared/node_modules/vue-i18n';
import { messages, defaultLocale } from "./locales/localization";
import { LocaleHelper } from "../../shared/core/helpers/localeHelper";
import { router } from './router/router';
import store from './../../shared/core/store';
import App from './App.vue';

import './css/bootstrap.css';
import './css/fonts.css';
import './css/style.css';
import './js/script.js';
import { INIT } from './js/script.js';

////////////////////////////////////////////////////////////////////////////////////
// GLOBAL CONFIG ///////////////////////////////////////////////////////////////////
Vue.prototype.$public = "/public/assets";
Vue.config.productionTip = false;

if (process.env.NODE_ENV === 'development') {
  Vue.config.devtools = true;
}

Vue.use(VueI18n);

store.dispatch("loadSettings").then((result) => {
  let culture = store.state.settings.Culture;
  if (culture === "" || culture === null || culture === undefined) {
      culture = "en-US";
  }
  Vue.prototype.$culture = culture;

  Vue.prototype.$t = function (...args:any[]) {
    const result = i18n.t(args);
    if (result === '') {
        return args[0];
    }
    return result;
  };

  const i18n: any = new VueI18n({
    locale: LocaleHelper.toLocaleCulture(culture),
    fallbackLocale: defaultLocale,
    messages,
    silentTranslationWarn: true
  });

  new Vue({
    router: router,
    store,
    i18n,
    render: h => h(App),
  }).$mount('#app');
  INIT();
});

























import { Component, Emit, Vue } from "vue-property-decorator";
import { Prop } from "vue-property-decorator";

@Component({})
export default class CheckBox extends Vue {
  @Prop({ default: false }) value;
  checked: boolean = false;

  mounted() {
    this.checked = this.value === true || this.value === "true" ? true : false;
  }

  @Emit("input")
  onChange(val: any) {
    return val;
  }
}























































































import Vue from "vue";
import Component from "vue-class-component";
import { CrudService } from "../core/services/crudService";
import { LocalStorage } from "../core/localStorage";
import store from "../core/store";
import { Settings } from "../core/services/configService";
import { Format } from "../core/helpers/formatHelper";
import { ShopService } from "../core/services/shopService";

@Component({
  components: {},
})
export default class DebtsPage extends Vue {
  error: string = "";
  debts: any = null;
  salesToPay: any = [];

  async mounted() {
    let sid = LocalStorage.getWithTimeout("cc");
    if (sid === undefined || sid === "") {
      store.commit("openLogin");
      return;
    }
    const cid = JSON.parse(sid);

    const url = `${Settings.HostName}/api/shop/pending-sales/${cid.id}`;
    let data = await new CrudService().httpPost(url);

    if (data === null || data.ok === false) {
      console.error("Crash getting checkout info " + cid);
      this.error = this.$t("Crash").toString();
      return;
    }

    this.debts = data.data;
  }

  selectDebt(debt: any) {
    const found = this.salesToPay.find((y) => y.saleNumber === debt.saleNumber);
    if (found === undefined) {
      this.salesToPay.push(debt);
      return;
    }

    this.salesToPay = this.salesToPay.filter(
      (item) => item.saleNumber !== found.saleNumber
    );
  }

  addToCart(e: any) {
    e.preventDefault();
    var shopService = new ShopService();
    for (const sale of this.salesToPay) {
      shopService.addSaleToCart(sale, e.currentTarget);
    }
  }

  formatPrice(price: string) {
    return Format.formatCurrency(price);
  }
}





































































































import { CrudService } from "../core/services/crudService";
import { Settings } from "../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";
import { Format } from "../core/helpers/formatHelper";

@Component
export default class BookingTypesPage extends Vue {
  dbBookingTypes: any = null;
  bookingTypes: any = null;
  errorMsg: string = "";
  categories: string[] = [];

  async mounted() {
    const url = `${Settings.HostName}/api/publicbookings/booking-types-list`;
    const data = await new CrudService().httpGet(url);
    if (data == null || data.model == null || !data.ok) {
      console.error("Crash getting index booking types");
      return;
    }
    this.dbBookingTypes = data.model;
    if (this.dbBookingTypes.length === 1) {
      this.$router.push(this.buildUrlBooking(this.dbBookingTypes[0]));
      return;
    }
    this.bookingTypes = data.model;
    this.setUpCategories();
  }

  setUpCategories() {
    for (const bt of this.dbBookingTypes) {
      if (bt.category === null || bt.category === undefined) {
        continue;
      }

      if (
        this.categories.find(
          (y) => y.toLowerCase() == bt.category.toLowerCase()
        ) == undefined
      ) {
        this.categories.push(bt.category);
      }
    }
  }

  getImagePath(img: string) {
    if (img === null || img === undefined || img === "") {
      return (
        this.$store.state.settings.Uploads +
        "/" +
        this.$store.state.settings.Mainlogo
      );
    }
    return this.$store.state.settings.Uploads + "/" + img;
  }

  buildUrlBooking(bt: any) {
    if (bt.bookingByPlace === true && bt.allowCreateBookByPlace === false) {
      return `/bookings-byplace/${bt.id}`;
    }
    return `/bookings/${bt.id}`;
  }

  filterByCategory(cat: string, e: any) {
    e.preventDefault();
    if (cat === "") {
      this.bookingTypes = this.dbBookingTypes;
      let dom: any = document.getElementById(`filter_all`);
      dom.classList.add("active");
      return;
    }

    this.bookingTypes = this.dbBookingTypes.filter(
      (y) =>
        y.category !== null && y.category.toLowerCase() === cat.toLowerCase()
    );
    for (const allcat of this.categories) {
      let domCat: any = document.getElementById(`filter_${allcat}`);
      domCat.classList.remove("active");
    }
    let domCat: any = document.getElementById(`filter_all`);
    domCat.classList.remove("active");

    let dom: any = document.getElementById(`filter_${cat}`);
    dom.classList.add("active");
  }

  formatCurrency(amount: string | null) {
    if (amount == null) {
      return "";
    }
    return Format.formatCurrency(amount as string);
  }
}

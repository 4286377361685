







































































import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import Popup from "../popup/popup.vue";
import store from "../../core/store";
import { Settings } from "../../core/services/configService";
import { CrudService } from "../../core/services/crudService";
import { LocalStorage } from "../../core/localStorage";
import InputPassword from "../../components/form/inputPassword.vue";
import InputText from "../../components/form/inputText.vue";
import SubmitButton from "../../components/submitButton/submitButton.vue";
import { Format } from "../../core/helpers/formatHelper";

@Component({
  components: { Popup, InputPassword, InputText, SubmitButton },
})
export default class LoginForm extends Vue {
  email: string = "";
  password: string = "";
  resetPassEmail: string = "";

  error: string = "";
  loginButtonTxt: string = "";

  resetPasswordMode: boolean = false;
  resetSent: boolean = false;
  registerOk: boolean = false;

  mounted() {
    this.error = "";
    this.loginButtonTxt = this.$t("Login").toString();
  }

  submit(e: any) {
    e.preventDefault();

    this.error = "";
    this.registerOk = false;

    if (this.resetSent) {
      this.closeLogin();
      return;
    }

    if (this.resetPasswordMode) {
      this.resetPassword();
      return;
    }

    this.login();
  }

  @Emit("onLogin")
  async login() {
    if (this.email.indexOf("@") === -1) {
      this.error =
        this.$t(
          "For security reasons, the user name to log into your account will be your email"
        ).toString() +
        ". " +
        this.$t(
          "You wont be able to use your phone number any more"
        ).toString() +
        ". " +
        this.$t("Please contact the center if you need assistance").toString();
      return;
    }

    let url = `${Settings.HostName}/api/publiccustomers/cauth`;
    let data = await new CrudService().httpPost(url, {
      email: this.email,
      password: this.password,
    });

    if (data == null) {
      console.error("Crash on login");
      return;
    }

    if (data.ok === false) {
      if (
        data.errorMsg !== "" &&
        data.errorMsg !== null &&
        data.errorMsg !== undefined
      ) {
        this.error = data.errorMsg;
        if (data.redirect !== undefined && data.redirect === true) {
          this.resetPassModeOn();
        }
        return;
      }
      this.error = this.$t("Invalid Email or Password").toString();
      return;
    }

    this.closeLogin();
    LocalStorage.addWithTimout("ctk", data.model.token, data.model.expiry);
    var c = {
      id: data.model.id,
    };

    LocalStorage.addWithTimout("cc", JSON.stringify(c), data.model.expiry);
    store.state.loggedIn = true;
    if (!Format.IsNull(store.state.loginRedirect)) {
      location.href = store.state.loginRedirect;
    }
    return { ok: true, id: data.model.id };
  }

  async resetPassword() {
    this.resetSent = true;
    this.loginButtonTxt = this.$t("Close").toString();

    let url = `${Settings.HostName}/api/publiccustomers/reset-password-request-cz`;
    let data = await new CrudService().httpPost(url, {
      email: this.resetPassEmail,
    });

    if (data == null) {
      console.error("Crash on reset-password-request-cz");
      return;
    }
  }

  @Emit("close")
  closeLogin() {}

  resetPassModeOn(e: any = null) {
    if (e !== null) {
      e.preventDefault();
    }

    this.loginButtonTxt = this.$t("Send").toString();
    this.resetPasswordMode = true;
  }
}

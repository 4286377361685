






















import { Component, Vue } from "vue-property-decorator";
import { Settings } from "../../core/services/configService";
import { CrudService } from "../../core/services/crudService";
import store from "../../core/store";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

@Component({
  components: {
    VueSlickCarousel,
  },
})
export default class Banners extends Vue {
  banners: any[] = [];
  carouselSettings: any = null;

  async mounted() {
    const url = `${Settings.HostName}/api/banners/open-list`;
    const data = await new CrudService().httpGet(url);
    if (data === null || data.rowData === null) {
      console.error("Crash getting banners");
      return;
    }

    for (const row of data.rowData) {
      this.banners.push({
        id: row.id,
        title: row.title,
        text: row.text,
        image:
          row.image === null
            ? null
            : store.state.settings.Uploads + "/" + row.image,
        url: row.url,
      });
    }

    this.carouselSettings = {
      arrows: false,
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 3000,
      autoplaySpeed: 3000,
      cssEase: "ease-in-out",
    };
  }
}

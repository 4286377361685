






















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component({
  components: {},
  filters: {
    legendCount: function (value) {
      let ss = "";
      if (value !== "" && value !== undefined) {
        ss = ": " + value;
      }
      return ss;
    },
  },
})
export default class ToolpanelMap extends Vue {
  @Prop({ default: {} }) public toolParams!: any;
  @Prop({ default: 1 }) public nrange!: number;

  public nrangecmp = this.nrange;

  @Watch("nrangecmp")
  private onNrangeChange(value: string, oldValue: string) {
    this.toolParams.nrange = this.nrangecmp;
    this.$emit("onChangeZoom", event);
  }
}


























import Vue from "vue";
import Component from "vue-class-component";
import { CrudService } from "../core/services/crudService";
import { Settings } from "../core/services/configService";

@Component({
  components: {},
})
export default class GeneralConditionsPage extends Vue {
  generalConditions: string = "";

  async mounted() {
    this.loadData();
  }

  async loadData() {
    let url = `${Settings.HostName}/api/czconfigs/legal-settings`;
    let data = await new CrudService().httpGet(url);
    if (data == null || data.data == null || !data.ok) {
      console.error("Crash getting cz legal settings");
      return;
    }

    this.generalConditions = data.data.generalConditions;
  }
}
